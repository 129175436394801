<div
  class="p-4 md:p-10 flex-1 flex flex-col align-middle text-mat-ef-revolution-primary-500"
>
  <div class="verify flex flex-col gap-6 md:gap-10">
    <div>
      <h2 class="font-canelablack-primary text-4xl md:text-5xl">
        {{ 'e-mail-confirmed' | sharedTranslate }}
      </h2>
    </div>
    <div
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      <T
        str="An email with further instructions has been sent to you. If you have not received an email, you can email to have a new email sent."
        key="customerPortal.b2c.invite-verify.text"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
    <div class="next">
      <button
        class="revolution min-w-[300px] min-h-[48px] font-bold button-spinner"
        mat-button
        mat-flat-button
        color="secondary"
        type="submit"
        (click)="redirect()"
        [disabled]="submitting"
      >
        <customer-portal-ef-spinner
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.7"
          [diameter]="16"
          *ngIf="submitting"
        >
        </customer-portal-ef-spinner>
        <div class="flex-1 flex items-center justify-between">
          {{ 'next' | sharedTranslate }}
          <mat-icon svgIcon="chevron_right"></mat-icon>
        </div>
      </button>
    </div>
  </div>
</div>
