import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable, map } from 'rxjs'
import { JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { RolesLevelEnum } from '../enum/roles-level.enum'
import { EntityType } from '../enum/departments.enum'

type CdbDepartmentDTO = com.mocira.inside.cdb.client.dto.CdbDepartmentDTO
type CdbDepartmentMinDTO = com.mocira.inside.cdb.client.dto.CdbDepartmentMinDTO

@Injectable({ providedIn: 'root' })

export class DepartmentsService extends BaseService {
  // TODO - implement new endpoint only with CdbDepartmentMinDTO
  getDepartmentList (parameters?): Observable<CdbDepartmentMinDTO[]> {
    let params = new HttpParams()

    params = params.append('filter[level]', EntityType.DEALER)
    params = params.append('page[size]', 100)

    if (parameters !== null) {
      for (const [key, value] of Object.entries(parameters)) {
        params = params.append(key, value as string)
      }
    }

    return this.http.get<JsonApiResponseList<CdbDepartmentDTO>>(
    `${this.apiUrl}/cdb/department/list`, { params }
    ).pipe(map(res => res?.data?.map(item => {
      return { id: item.attributes.id, name: item.attributes.name }
    })))
  }

  getDepartmentsList (
    rolesLevel: RolesLevelEnum | RolesLevelEnum[],
    withPermissionOnly?: boolean
  ): Observable<JsonApiResponseList<CdbDepartmentDTO>> {
    const params = {}
    params['filter[level]'] = rolesLevel
    params['page[size]'] = 100

    if (withPermissionOnly !== undefined) {
      params['withPermissionOnly'] = withPermissionOnly
    }

    const config = { params }

    return this.http.get<JsonApiResponseList<CdbDepartmentDTO>>(
      `${this.apiUrl}/cdb/department/list?sort=orderNumber,roles.orderNumber`,
      config
    )
  }

  getDepartments (parameters?): Observable<JsonApiResponseList<CdbDepartmentDTO>> {
    let params = new HttpParams()

    // params = params.append('filter[level]', EntityType.DEALER)
    params = params.append('page[size]', 100)

    if (parameters !== null) {
      for (const [key, value] of Object.entries(parameters)) {
        params = params.append(key, value as string)
      }
    }

    return this.http.get<JsonApiResponseList<CdbDepartmentDTO>>(
      `${this.apiUrl}/cdb/department/list`, { params }
    )
  }
}
