<div
  class="cpt-modal relative ef-scrollbar file-preview image-preview justify-center"
>
  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <div class="w-full min-h-[60%] h-fit relative mt-8">
    <div *ngIf="data.preview && data.format === 'pdf'" class="w-full h-fit">
      <div class="file-box">
        <div class="w-full h-full">
          <ngx-extended-pdf-viewer
            class="ngx-extended-pdf-viewer"
            [src]="data.preview"
            [showToolbar]="true"
            [showDownloadButton]="true"
            [useBrowserLocale]="true"
            [zoom]="'page-width'"
          >
          </ngx-extended-pdf-viewer>
        </div>
      </div>
    </div>

    <img
      class="w-full max-h-[calc(100%_-_50px)] h-[calc(100%_-_50px)]"
      [src]="image"
      alt="image preview"
      *ngIf="
        data.format === 'jpg' || data.format === 'jpeg' || data.format === 'png'
      "
    />
  </div>
</div>
