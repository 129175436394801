import { HubConfigurationValue } from './hub-configuration.model'

export interface HubMobileConfiguration {
  services: {
    pp: {
      environment: {
        domain: string
        baseUrl: string
        authorizationExcludedUrls: Array<{ pattern: string }>
      }
    }
  },

  /**
   * FFU backend URL.
   */
  'api.ffu-url': string

  /**
   * Base URL for the Customer Profile backend
   */
  'api.cp-url': string

  /**
   * Base URL for Partner Profile backend
   */
  'api.pp-url': string

  /**
   * Is keycloak login enabled
   */

  'hub.keycloak.enabled': boolean

  /**
   * Is two factor login enabled
   */

  'hub.2fa.enabled': boolean

  /**
   * Server host for auth service
   */
  'hub.auth-config.server-host': string

  /**
   * Keycloak client id - url's and other connections/settings for client
   */
  'hub.auth-config.client-id': string

  /**
   * Redirect url after keycloak login
   **/
  'hub.auth-config.redirect-url': string

  /**
   * Redirect url after keycloak logout
   **/
  'hub.auth-config.end-session-redirect-url': string

  /**
   * Auth scopes supported (openid, profile, email, address, phone, offline_access)
   **/
  'hub.auth-config.scopes': string

  /**
   * Proof Key for Code Exchange (prevents authorization code interception attacks)
   **/
  'hub.auth-config.pkce': boolean

  /**
   * Onesignal application id
   **/
  'hub.onesignal.app-id': string

  /**
   * Onesignal force subscription
   **/
  'hub.onesignal.force-subscription': boolean

  /**
   * Transifex token to fetch translations
   */
  'hub.transifex.token': string

  /**
   * Language ID.
   */
  'hub.translations.languages.0.id': string

  /**
   * The name of the language.
   */
  'hub.translations.languages.0.name': string

  /**
   * Language's Partner Profile code.
   */
  'hub.translations.languages.0.code': string

  /**
   * Language's Transifex code.
   */
  'hub.translations.languages.0.transifex-code': string

  /**
   * General hub currency used by currency pipe (e.g. CHF)
   */
  'hub.localization.currency': string

  /**
   * The current country code.
   */
  'hub.localization.countryCode': string

  /**
   * Personal name convention user througout the app.
   * Possible values: first_last, last_first
   */
  'hub.localization.personalNameConvention': string

  /**
   * Standardized date format string.
   */
  'hub.localization.dateFormat': string

  /**
   * Standardized month with year format string.
   */
  'hub.localization.monthYearFormat': string

  /**
   * Accessible date label format string.
   */
  'hub.localization.dateA11yLabelFormat': string

  /**
   * Accessible month year label format string.
   */
  'hub.localization.monthYearA11yLabelFormat': string

  /**
   * Standardized moment date format string.
   */
  'hub.localization.momentDateFormat': string

  /**
   * Standardized moment month with year format string.
   */
  'hub.localization.momentMonthYearFormat': string

  /**
   * Accessible moment date label format string.
   */
  'hub.localization.momentDateA11yLabelFormat': string

  /**
   * Accessible moment month with year label format string.
   */
  'hub.localization.momentMonthYearA11yLabelFormat': string

  /**
   * Standardized time format string.
   */
  'hub.localization.timeFormat': string

  /**
   * Default locale ID.
   */
  'hub.localization.defaultLocale.lcid': string

  /**
   * Default locale language code.
   */
  'hub.localization.defaultLocale.languageCode': string

  /**
   * Locale ID for a locales option.
   */
  'hub.localization.locales.0.lcid': string

  /**
   * Language code for a locales option.
   */
  'hub.localization.locales.0.languageCode': string

  /**
   * Language list for template for email in out widget
   */
  'hub.templates.languages': string[]

  /**
   * Available languages in application
   */
  'appLocales': string[]

  /**
   * Used to enable owner change flow for certain types of contacts.
   */
  'modules.ef-ng-contact-search-mobile.type-list': string[]

  /**
   * Used to specify contact status list to be used for owner change flow requirements.
   */
  'modules.ef-ng-contact-search-mobile.status-list': string[]

  /**
   * Info if azure search is enabled on this environment for adjusted contact list sorting logic.
   */
  'modules.ef-ng-contact-search-mobile.azure-enabled': boolean

  /**
   * Send dealer id parameter in contact search
   */
  'modules.ef-ng-contact-search-mobile.disable-dealer-id-in-contact-search': boolean

  /**
   * Enable combined KDB search
   */
  'modules.ef-ng-contact-search-mobile.kdb-combo-search': boolean

  /**
   * Default source id for new lead
   */
  'modules.ef-ng-ffu-new-deal-mobile.default-source': number

  /**
   * Default sub source id for new lead
   */
  'modules.ef-ng-ffu-new-deal-mobile.default-sub-source': number

  /**
   * Default importer id for lead group
   */
  'modules.ef-ng-ffu-new-deal-mobile.group.importer.id': number

  /**
   * Default importer name for lead group
   */
  'modules.ef-ng-ffu-new-deal-mobile.group.importer.name': string

  /**
   * Default dealer id for lead group
   */
  'modules.ef-ng-ffu-new-deal-mobile.group.dealer.id': number

  /**
   * Default dealer name for lead group
   */
  'modules.ef-ng-ffu-new-deal-mobile.group.dealer.name': string

  /**
   * Flag to control displaying of SLA qualification column on lead overview
   */
  'modules.ef-ng-ffu-lead-overview-mobile.sla.show-sla-qualification': boolean

  /**
   * Delay in milliseconds after which remaining time for SLA is checked
   */
  'modules.ef-ng-ffu-lead-overview-mobile.sla.timer': number

  /**
   * Flag to control preventing SLA counter to go to negative value
   */
  'modules.ef-ng-ffu-lead-overview-mobile.sla.expire-to-zero': boolean

  /**
   * Flag to control displaying of SLA column on lead overview table
   */
  'modules.ef-ng-ffu-lead-overview-mobile.sla.show-sla': boolean

  /**
   * Flag to control displaying of SLA column on lead overview table
   */
  'modules.ef-ng-ffu-lead-overview-mobile.sla.days-to-skip': string[]

  /**
   * Flag to control displaying of 24 hours SLA column on lead overview table
   */
  'modules.ffu.sla24.show-sla': boolean

  /**
   * Delay in milliseconds after which remaining time for 24 hours SLA is checked
   */
  'modules.ffu.sla24.timer': number

  /**
   * Brands on which 24 SLA should be displayed
   */
  'modules.ffu.sla24.0.brand': string

  /**
   * Flag to display the filtered list of statuses on lead overview
   */
  'modules.ffu.lead-overview-mobile.status.show-filtered-list': boolean

  /**
   * Brand name property for call attempts on lead details
   */
  'modules.ffu.call-attempts.0.brand-name': string

  /**
   * Number of attempts for call attempts on lead details
   */
  'modules.ffu.call-attempts.0.number': number

  /**
   * Default value of attempts for call attempts on lead details
   */
  'modules.ffu.default-number-of-call-attempts': number

  /**
   * Flag to encode file name
   */
  'modules.hub-client.filename-encoding': boolean

  [key: string]: HubConfigurationValue | any
}

export type HubMobileConfigurationKey = keyof HubMobileConfiguration
