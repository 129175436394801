import { Component, Inject } from '@angular/core'
import {
  MatLegacySnackBar as MatSnackBar,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA
} from '@angular/material/legacy-snack-bar'
import { TransifexService } from '../../../services/transifex.service'

export interface SnackBarData {
  translationKey?: string
  translationKeyArray?: string[]
  action?: boolean
  text?: string
}
export interface SnackBarConfig {
  data: SnackBarData
  duration?: number
  horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right'
  verticalPosition?: 'top' | 'bottom'
}

@Component({
  selector: 'customer-portal-app-snackbar',
  templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
  isArray = false
  constructor (
    @Inject(MAT_SNACK_BAR_DATA) public snackBarData: SnackBarData,
    public snackBar: MatSnackBar,
    public transifexService: TransifexService
  ) {
    this.isArray = snackBarData.translationKeyArray != null
  }

  getTranslation (key): string {
    return this.transifexService.getTranslation({ key })
  }
}
