export interface HubEnvironmentMeta {
  brand: 'multibrand' | string
  configUrl: 'https://configuration.emilfreydigital.hr' | string
  country: 'ch' | 'hr' | string
  environment: string
  product: 'inside-hub' | string
  type: 'app' | string
}

export interface HubConfigurationApplication {
  enabled: boolean
  integration?: {
    url: string
  }
}

export interface Language {
  id: string
  name: string
  code: string
  transifexCode: string
}

export interface Translations {
  types: TranslationType[]
  defaultLanguage: string
  fallbackLanguages: string[]
  [key: string]: any
}

export interface TranslationType {
  type: string
  prefix: string
}

/**
 * Type of value which can be returned from the configuration. Allows no hierarchy.
 */
export type HubConfigurationValue = string | string[] | boolean | number | number[]

/**
 * Hub Configuration Interface
 */
export interface HubConfiguration {
  applications: {
    ffu: HubConfigurationApplication
    hub: HubConfigurationApplication
  }
  services: any
  translations: Translations
  bi?: any
  appLocales: string[]
  common: {
    i18n: {
      // language, currency and other internationalization specifics
    }
    social: {
      facebook: {
        appId: string
        apiVersion: string
        scope: string
        pagesPageSize: number
      }
    }
  }
  keys?: {
    googleMaps?: string
    [key: string]: any
  }
  settings?: {
    general?: {
      defaultTelephoneCountry?: string
    }
  }
  sentry: {
    dsn: string
    // https://docs.sentry.io/platforms/javascript/guides/angular/performance/instrumentation/automatic-instrumentation/
    performanceTracing: {
      enabled: boolean
      tracingOrigins?: string[] // domain list
    }
    tracesSampleRate: number // 0.0 - 1.0
  }
  [key: string]: HubConfigurationValue | any

  /**
   * base url for v360-client library
   *
   * @link https://insidevehicle360.docs.apiary.io/
   */
  'api.v360-url': string

  /**
   * Base url for c360-client library
   *
   * @link https://customer3602.docs.apiary.io/
   */
  'api.c360-url': string

  /**
   * Base URL for the DMS API. Formerly known as centralContentUrl
   */
  'api.dms-api-url': string

  /**
   * API url to marketing mangagement backend
   */
  'api.marketing-url': string

  /**
   * API url to eflex backend
   */
  'api.eflex-url': string

  /**
   * API url to promotions admin data
   */
  'api.pa-data-url': string

  /**
   * API url to process-support-module (psm) backend
   */
  'api.psm-url': string

  /**
   * App URL.
   */
  'api.app-url': string

  /**
   * App URL for efit integration
   */
  'api.vp-efit-integration': string
  /**
   * Base URL for the Customer Profile backend
   */
  'api.cp-url': string

  /**
   * Base URL for the Customer Profile backend V2
   */
  'api.cp-url-v2': string

  /**
   * Base URL for the Customer Portal API
   */
  'api.customer-portal-url': string

  /**
   * FFU backend URL.
   */
  'api.ffu-url': string

  /**
   * HUB backend URL.
   */
  'api.hub-url': string

  /**
   * HUB authorization service URL.
   */
  'api.hub-authorization-service-url'

  /**
   * INSiDE backend url
   */
  'api.inside-url': string

  /**
   * PP backend URL.
   */
  'api.pp-url': string

  /**
   * VP backend URL.
   */
  'api.vp-url': string

  /**
   * API url to promotions admin backend
   */
  'api.pa-url': string

  /**
   * API url to vehicle profile care backend
   */
  'api.vp-care-url': string

  /**
   * API url to subscription backend
   */
  'api.saas-url': string

  /**
   * API base url to EFIT backend
   */
  'api.efit-api-url': string

  /**
   * API url to EFIT spare parts backend
   */
  'api.efit-spare-parts-url': string

  /**
   * URL to HUB websocket API
   */
  'api.hub-ws-url': string

  /**
   * API url to Sales Module backend
   */
  'api.sm-url': string

  /**
   * Url for fetching vehicle images using Azure CDN
   */
  'api.inside-vehicle-public-images-url': string

  /**
   * Log level for debug builds.
   *
   * 1 (meaning DEBUG and above) would be enough for debug builds.
   *
   * @link https://github.com/dbfannin/ngx-logger/blob/master/src/lib/types/logger-level.enum.ts
   */
  'hub.log.debug-level': number

  /**
   * Log level for production builds.
   *
   * 4 (meaning WARN and above) would be enough for production builds.
   *
   * @link https://github.com/dbfannin/ngx-logger/blob/master/src/lib/types/logger-level.enum.ts
   */
  'hub.log.production-level': number

  /**
   * Camel case name of brand for which redirection is necessary
   */
  'hub.redirect.0.brand': string

  /**
   * Redirection url for certain brand
   */
  'hub.redirect.0.url': string

  /**
   * ID for Sentry.
   */
  'hub.sentry.dsn': string

  /**
   * Is performance tracing enabled for Sentry.
   */
  'hub.sentry.performance-tracing.enabled': boolean

  /**
   * List of comma-separated tracing origins for Sentry tracing header.
   */
  'hub.sentry.tracing-origins': string

  /**
   * Desired Sentry sample rate.
   */
  'hub.sentry.traces-sample-rate': number

  /**
   * Enabled API performance tracking.
   */
  'hub.api-performance-tracking.enabled': boolean

  /**
   * Provider for API performance tracking
   */
  'hub.api-performance-tracking.provider': 'sentry' | 'generic'

  /**
   * API performance tracking minimal duration of req that will be logged in seconds
   */
  'hub.api-performance-tracking.min-duration': number

  /**
   * List of tracked API URLs for performance tracking
   */
  'hub.api-performance-tracking.url-whitelist': string[]

  /**
   * The Google Maps API Key (see:
   * https://developers.google.com/maps/documentation/javascript/get-api-key)
   */
  'hub.googleMapsApi.key': string

  /**
   * The Google Maps API Key (see:
   * https://developers.google.com/maps/documentation/javascript/get-api-key)
   */
  'hub.googleMapsApi.hostAndPath': string

  /**
   * The current country code.
   */
  'hub.localization.countryCode': string

  /**
    * Personal name convention user througout the app.
    * Possible values: first_last, last_first
    */
  'hub.localization.personalNameConvention': string

  /**
    * Standardized date format string.
    */
  'hub.localization.dateFormat': string

  /**
    * Standardized month with year format string.
    */
  'hub.localization.monthYearFormat': string

  /**
    * Accessible date label format string.
    */
  'hub.localization.dateA11yLabelFormat': string

  /**
    * Accessible month year label format string.
    */
  'hub.localization.monthYearA11yLabelFormat': string

  /**
    * Standardized moment date format string.
    */
  'hub.localization.momentDateFormat': string

  /**
    * Standardized moment month with year format string.
    */
  'hub.localization.momentMonthYearFormat': string

  /**
    * Accessible moment date label format string.
    */
  'hub.localization.momentDateA11yLabelFormat': string

  /**
    * Accessible moment month with year label format string.
    */
  'hub.localization.momentMonthYearA11yLabelFormat': string

  /**
    * Standardized time format string.
    */
  'hub.localization.timeFormat': string

  /**
    * Locale ID for a locales option.
    */
  'hub.localization.locales.0.lcid': string

  /**
    * Language code for a locales option.
    */
  'hub.localization.locales.0.languageCode': string

  /**
    * Default locale ID.
    */
  'hub.localization.defaultLocale.lcid': string

  /**
    * Default locale language code.
    */
  'hub.localization.defaultLocale.languageCode': string

  /**
    * Use filename encoding.
    */
  'hub.localization.filenameEncoding': boolean

  /**
    * Apply email validation.
    */
  'hub.localization.emailValidation': boolean

  /**
   * General hub currency used by currency pipe (e.g. CHF)
   */
  'hub.localization.currency': string

  /**
   * General hub currency locale used by currency pipe (e.g. de-CH)
   */
  'hub.localization.currency-locale': string

  /**
   * Enables the matomo analytics integration
   */
  'hub.matomo.enabled': boolean

  /**
   * Matomo site ID
   */
  'hub.matomo.site-id': string

  /**
   * Matomo tracker url
   */
  'hub.matomo.tracker-url': string

  /**
   * The url to redirect to if we're on mobile. If this is empty, the mobile-redirect feature is disabled.
   */
  'hub.mobile-redirect.url': string

  /**
   * Enables or disabled hub notifications. Replaces the old nested modules.hubCommon.notifications.enabled.
   */
  'hub.notifications.enabled'

  /**
   * Product fruits key indicating the product fruits project to use. If there is no key, product fruits won't initialize.
   */
  'hub.product-fruits.key': string

  /**
   *  Multi-brand redirect url, used on single brand instances if user dealer has multiple brands
   */
  'hub.redirect.multi-brand': string
  /**

  /**
   * Enables the product fruits button on the sidebar
   */
  'hub.product-fruits.button': boolean

  /**
   * Enable hub starred button for favourite/starred filters (currently only on stock)
   */
  'hub.starred-button.enabled': boolean

  /**
   * Array of languages used in templates modal and templates editor
   */
  'hub.templates.lanuguages': string[]

  /**
   * Transifex token to fetch translations
   */
  'hub.transifex.token': string

  /**
   * App id for OneSignal integration
   */
  'hub.onesignal.app-id': string

  /**
   * OneSignal integration enabled
   */
  'hub.onesignal.enabled': boolean

  /**
   * Language ID.
   */
  'hub.translations.languages.0.id': string

  /**
   * Language's Partner Profile code.
   */
  'hub.translations.languages.0.code': string

  /**
   * The name of the language.
   */
  'hub.translations.languages.0.name': string

  /**
   * Language's Transifex code.
   */
  'hub.translations.languages.0.transifex-code': string

  /**
   * API key used for verification/access to CPT backend.
   */
  'modules.customer-profile.integrations.cpt.api-key': string

  /**
   * Source code used for tracking CPT invitation source.
   */
  'modules.customer-profile.integrations.cpt.source': string

  /**
   * Used for identifying CP as source on contact usage.
   */
  'modules.customer-profile.account.number': string

  /**
   * Used for identifying CP as source on consent&declaration usage.
   */
  'modules.customer-profile.account.source': string

  /**
   * Used to enable/disable anonymization functionalities on a certain environment.
   */
  'modules.customer-profile.anonymization.enabled': boolean

  /**
   * If anonymization is present on an environment, this is the pattern used for email validation.
   */
  'modules.customer-profile.anonymization.email': string

  /**
   * If anonymization is present on an environment, this is the  pattern used for number validation.
   */
  'modules.customer-profile.anonymization.number': string

  /**
   * Enable omikron address autocomplete as opposed to Google address autocomplete
   */
  'modules.customer-profile.autocomplete.address': boolean

  /**
   * Declaration tag max allowed length.
   */
  'modules.customer-profile.declarations.tags.validation.max-length': number

  /**
   * EF Group name; relevant on environments with DE version of GDPR(i.e. DE).
   */
  'modules.customer-profile.general.ef-group.name': string

  /**
   * EF Group address; relevant on environments with DE version of GDPR(i.e. DE).
   */
  'modules.customer-profile.general.ef-group.address': string

  /**
   * Used for distinguishing GDPR logic to be applied on a certain environment;
   * possible values are 'CE' or 'DE'.
   */
  'modules.customer-profile.general.gdpr-type': string

  /**
   * Used for hiding additional address fields/salutation upon create/update of contact form.
   */
  'modules.customer-profile.general.hide-on-update': boolean

  /**
   * Used for forcing form enabled regardless of restricting factors, i.e. contact status, ACL etc.
   */
  'modules.customer-profile.general.ignore-form-disable': boolean

  /**
   * Used for triggering address validation on contact form load.
   */
  'modules.customer-profile.on-load-validation': boolean

  /**
    * Used for switching between INSiDE Leads tab view and new FFU version of the same.
    */
  'modules.customer-profile.use-inside-data': boolean

  /**
   * Enable alphanumeric validation on tax ID(OIB in CE countries).
   * If true, OIB can have number of characters up to number defined in parameter: oibLength and all characters are supported.
   * If false, OIB must have exact number of characters defined in parameter:oibLength and all characters should be numeric.
   */
  'modules.customer-profile.views.contact.alphanumeric-tax-id': boolean

  /**
   * Defines allowed number of characters for additional name in contact form.
   */
  'modules.customer-profile.views.contact.additional-name-validation': number

  /**
   * Defines if address data quality fields are displayed on contact form.
   */
  'modules.customer-profile.views.contact.address-dq-enabled': boolean

  /**
   * Defines if email data quality fields are displayed on contact form.
   */
  'modules.customer-profile.views.contact.email-dq-enabled': boolean

  /**
   * Defines contact types on which Omikron data quality check is performed.
   */
  'modules.customer-profile.views.contact.dq.type': string[]

  /**
   * Defines contact statuses on which Omikron data quality check is performed.
   */
  'modules.customer-profile.views.contact.dq.status': string[]

  /**
   * Used for triggering display of the business salutation dropdown in contact form.
   */
  'modules.customer-profile.views.contact.business-salutation-enabled': boolean

  /**
   * Defines allowed number of characters for city name in contact form.
   */
  'modules.customer-profile.views.contact.city-validation': number

  /**
   * Defines allowed number of characters for company name1 in contact form.
   */
  'modules.customer-profile.views.contact.company-validation': number

  /**
   * Defines allowed number of characters for company name2 in contact form.
   */
  'modules.customer-profile.views.contact.company2validation': number

  /**
   * Defines allowed number of characters for company additional name in contact form.
   */
  'modules.customer-profile.views.contact.company-additional-name-validation': number

  /**
   * Defines allowed number of characters for company name1 if name2 exists in contact form.
   */
  'modules.customer-profile.views.contact.company-complete-validation': number

  /**
   * Defines the default selected language in the primary language field of the contact form.
   */
  'modules.customer-profile.views.contact.default-primary-language': string

  /**
   * Trigger checking for address duplicates on contact create/update on/off.
   */
  'modules.customer-profile.views.contact.disable-address-duplicate-check': boolean

  /**
   * Disable address preview on contact card.
   */
  'modules.customer-profile.views.contact.disable-address-preview': boolean

  /**
   * Disable changing contact type on new contact.
   */
  'modules.customer-profile.views.contact.disable-customer-select': boolean

  /**
   * Make contact email field optional(turn off mandatory) in contact form.
   */
  'modules.customer-profile.views.contact.disable-mandatory-email': boolean

  /**
   * Used to disable email validation in contact form.
   */
  'modules.customer-profile.views.contact.disable-validation-email': boolean

  /**
   * Used to disable ZIP being checked on address change/update in contact form.
   */
  'modules.customer-profile.views.contact.disable-zip-check': boolean

  /**
   * Defines allowed number of characters for first name in contact form.
   */
  'modules.customer-profile.views.contact.first-name-validation': number

  /**
   * Used for triggering display of additional address fields(delivery, invoice) in contact form.
   */
  'modules.customer-profile.views.contact.hide-additional-addresses': boolean

  /**
   * Used to disable contact type conversion through contact form.
   */
  'modules.customer-profile.views.contact.hide-conversion': boolean

  /**
   * Used to display Care ID in search results&contact form. If false or nonexisting, contact customer/interest ID are displayed.
   */
  'modules.customer-profile.views.contact.hide-customer-id': boolean

  /**
   * Used to hide the personal number field from contact form.
   */
  'modules.customer-profile.views.contact.hide-customer-number': boolean

  /**
   * Used to hide the 'email denied' checkbox from contact form.
   */
  'modules.customer-profile.views.contact.hide-email-denied': boolean

  /**
   * Used to hide phone fields of type 'fax' from contact form.
   */
  'modules.customer-profile.views.contact.hide-fax-phones': boolean

  /**
   * Used to hide salesman info from leads list on contact leads tab.
   */
  'modules.customer-profile.views.contact.hide-lead-salesman': boolean

  /**
   * Used to hide the 'no email' checkbox from contact form.
   */
  'modules.customer-profile.views.contact.hide-no-email': boolean

  /**
   * Used to apply OIB specific logic on vatNumber contact property.
   */
  'modules.customer-profile.views.contact.is-using-oib': boolean

  /**
   * Used to display the VAT number country dropdown in contact form.
   */
  'modules.customer-profile.views.contact.is-using-vat-country': boolean

  /**
   * Defines allowed number of characters for last name in contact form.
   */
  'modules.customer-profile.views.contact.last-name-validation': number

  /**
   * If OIB validation is enabled, number of allowed characters for contact OIB(taxId).
   */
  'modules.customer-profile.views.contact.oib-length': number

  /**
   * If OIB has certain contact type, the field will be mandatory.
   */
  'modules.customer-profile.views.contact.oib-required': string[]

  /**
   * If VAT number has certain contact type, the field will be mandatory.
   */
  'modules.customer-profile.views.contact.eu-vat-number-required': string[]

  /**
   * If Identification number has certain contact type, the field will be mandatory.
   */
  'modules.customer-profile.views.contact.identification-number-required': string[]

  /**
   * Used for VAT number length validation.
   */
  'modules.customer-profile.views.contact.eu-vat-number-length': number

  /**
   * Used for VAT number regex validation.
   */
  'modules.customer-profile.views.contact.eu-vat-number-regex': string

  /**
   * Used for VAT number validation, if there are country options
   */
  'modules.customer-profile.views.contact.eu-vat-number-country': boolean

  /**
   * Used to enable OIB specific validation on taxId field in contact form.
   */
  'modules.customer-profile.views.contact.oib-validation': boolean

  /**
   * Used to enable country ordering by translation.
   */
  'modules.customer-profile.views.contact.translated-country-order': boolean

  /**
   * Used to enable the personal number lookup functionality in contact form.
   */
  'modules.customer-profile.views.contact.personal-number-lookup': boolean

  /**
   * Used to list contact relations types which should be returned from backend.
   */
  'modules.customer-profile.views.contact.relations.codes': string | string[]

  /**
   * Used to enable field-level ACL use in contact form.
   */
  'modules.customer-profile.views.contact.set-form-controls-by-role': boolean

  /**
   * Used to enable Salesflow ID display on vehicle history tab items.
   */
  'modules.customer-profile.views.contact.show-salesflow-id': boolean

  /**
   * Defines allowed number of characters for street name in contact form.
   */
  'modules.customer-profile.views.contact.street-validation': number

  /**
   * Used to enable/disable contact title dropdown with regards to salutation.
   */
  'modules.customer-profile.views.contact.toggle-title': boolean

  /**
   * Enable toggling house number validation in contact form on/off. Default is false.
   */
  'modules.customer-profile.views.contact.toggle-street-number-validation': boolean

  /**
   * Enable OIB(taxId) validation with regards to country(HR specific).
   */
  'modules.customer-profile.views.contact.validate-oib-by-country': boolean

  /**
   * Used to enable/disable input of multiple general agreement numbers (Rahmenvertrag-nummer) in contact form.
   */
  'modules.customer-profile.views.contact.multiple-agreement-numbers': boolean

  /**
   * Determines whether contact type (person/company) can be switched. Does not affect contacts of type interest.
   */
  'modules.customer-profile.views.contact.type-switch-disabled': boolean

  /**
   * Determines the max allowed ZIP number length.
   */
  'modules.customer-profile.views.contact.zip-validation': number

  /**
   * Used to define a default set of visible columns among the ones displayed in contact list table.
   */
  'modules.customer-profile.views.overview.default-columns': string[]

  /**
   * Used to enable/disable opening GDPR document after signing.
   */
  'modules.customer-profile.views.gdpr.document-after-signature': boolean

  /**
   * Used to enable/disable GDPR functionality on a certain environment.
   */
  'modules.customer-profile.views.gdpr.enabled': boolean

  /**
   * Used to force enabling consent checkbox in some cases on CE version of GDPR.
   */
  'modules.customer-profile.views.gdpr.force-checkbox-enabled': boolean

  /**
   * Used to force importer specific logic in some cases on CE version of GDPR.
   */
  'modules.customer-profile.views.gdpr.force-importer-types': boolean

  /**
   * Used to trigger display of legal info on entities in GDPR table.
   */
  'modules.customer-profile.views.gdpr.legal-info.enabled': boolean

  /**
   * Used to store the Mercedes-Benz brand PP ID.
   */
  'modules.customer-profile.views.gdpr.one-doc.brand': string

  /**
   * Used to define the OneDoc pilot dealer PP ID if pilot version is enabled; other ones continue on regular GDPR CE logic.
   */
  'modules.customer-profile.views.gdpr.one-doc.dealer': string

  /**
   * Used to store the Mercedes-Benz EcoSystem PP ID.
   */
  'modules.customer-profile.views.gdpr.one-doc.ecosystem': string

  /**
   * Used to enable OneDoc specific features on GDPR.
   */
  'modules.customer-profile.views.gdpr.one-doc.enabled': boolean

  /**
   * Used to enable OneDoc specific features on GDPR on one pilot dealer; other ones continue on regular GDPR CE logic.
   */
  'modules.customer-profile.views.gdpr.one-doc.pilot': boolean

  /**
   * GDPR Consent configuration type used in table view.
   */
  'modules.customer-profile.views.gdpr.table-configuration-type': string

  /**
   * Used to enable the EF verification flow on GDPR tab.
   */
  'modules.customer-profile.views.gdpr.verification': boolean

  /**
   * Used to enable forcing invalid address through address modal on contact form.
   */
  'modules.customer-profile.form-fields.force-address-button': boolean

  /**
   * Used to hide contact ID from contact form on existing contacts.
   */
  'modules.customer-profile.form-fields.hide-contact-id': boolean

  /**
   * Hide title(dropdown) from contact form (for type person).
   */
  'modules.customer-profile.form-fields.hide-title': boolean

  /**
   * Hide Inbox tab from contact card.
   */
  'modules.customer-profile.hidden-tabs.inbox': boolean

  /**
   * Hide Model History tab from contact card.
   */
  'modules.customer-profile.hidden-tabs.model-history': boolean

  /**
   * Hide Offers&Contracts tab from contact card.
   */
  'modules.customer-profile.hidden-tabs.offer-contracts': boolean

  /**
   * Info if azure search is enabled on this environment for adjusted contact list sorting logic.
   */
  'modules.customer-profile.search.azure-enabled': boolean

  /**
   * Used to enable/disable contact search autocomplete (not including dashboard search).
   */
  'modules.customer-profile.search.autocomplete-enabled': boolean

  /**
   * Search contacts by Care ID.
   */
  'modules.customer-profile.search.care-id-search': boolean

  /**
   * Used to enable/disable contact search autocomplete only on dashboard search.
   */
  'modules.customer-profile.search.dashboard-autocomplete-enabled': boolean

  /**
   * Used to hide contact source in search result items on dashboard search.
   */
  'modules.customer-profile.search.hide-source': boolean

  /**
   * Pull contacts directly with their MDM ID(skipping the /pull-contact with customer ID in case of KDB) .
   */
  'modules.customer-profile.search.ignore-customer-id': boolean

  /**
   * Used to enabled search with the CP v1/search-contacts endpoint.
   */
  'modules.customer-profile.search.is-using-advanced-search': boolean

  /**
   * Used to enable the dashboard advanced search functionality on this environment.
   */
  'modules.customer-profile.search.new-advanced-search': boolean

  /**
   * Used to enable Totus specific validation of contact data in form.
   */
  'modules.customer-profile.microservices.totus.validation.enabled': boolean

  /**
   * Totus specific validation for name1 value in contact form.
   */
  'modules.customer-profile.microservices.totus.validation.name1': number

  /**
   * Totus specific validation for name2 value in contact form.
   */
  'modules.customer-profile.microservices.totus.validation.name2': number

  /**
   * Totus specific validation for additional name value in contact form.
   */
  'modules.customer-profile.microservices.totus.validation.additional-name': number

  /**
   * Model history component switcher
   */
  'modules.customer-profile.use-v360-model-history': boolean

  /**
   * Restrict if it possible to open V360 via action button on Model history table
   */
  'modules.customer-profile.model-history.open-v360-details': boolean

  /**
   * Add ability to create/edit/delete frequent driver via action button on Model history table
   */
  'modules.customer-profile.model-history.modify-frequent-driver': boolean

  /**
   * Dealer groups contacts update permissions override
   */
  'modules.customer-profile.contacts.restrict-dealer-update': boolean

  /**
   * Sets the support email on the dashboard.
   */
  'modules.ef-hub-dashboard.supportEmail': string

  /**
   * Controls the display of affected user email field on support form
   */
  'modules.ef-hub-dashboard.support-form.affected-user-email.enabled': boolean

  /**
   * Comma separated list of support form area field values, form is disabled when values prefixed with "#" are selected
   */
  'modules.ef-hub-dashboard.support-form.area.values': string

  /**
   * Comma separated list of support form impact field values
   */
  'modules.ef-hub-dashboard.support-form.impact.values': string

  /**
   * Comma separated list of support form urgency field values
   */
  'modules.ef-hub-dashboard.support-form.urgency.values': string

  /**
   * Comma separated list of support form request type field values. If value is prefixed with area, it will only be available when that area is selected. Example: EFIT.Incident => Incident option will only be available when EFIT area is selected
   */
  'modules.ef-hub-dashboard.support-form.request-type.values': string

  /**
   * The base url for manuals.
   */
  'modules.ef-hub-dashboard.manualsUrl': string

  /**
   * Values displayed in support form modules select control. Comma separated list of strings
   */
  'modules.ef-hub-dashboard.modules': string

  /**
   * Used to control the display of modules select control on support form
   */
  'modules.ef-hub-dashboard.modules-enabled': boolean

  /**
   * Used to set number of displayed tasks in the task widget table on dashboard
   */
  'modules.ef-hub-dashboard.tasks-widget.number-of-tasks': number

  /**
   * Used to set number of displayed rows in the extended widget table on dashboard
   */
  'modules.ef-hub-dashboard.widgets-extended.number-of-displayed-results': number

  /**
   * Used to enable user support pages based on MIPS provided data
   */
  'modules.ef-hub-dashboard.mipsSupport': boolean
  /**
   * Flag for showing yearly price instead of monthly for subscription features and licences
   */
  'modules.ef-ng-subscription.show-yearly-price': boolean

  /**
   * Flag for showing dealer name column in subscription user logins table
   */
  'modules.ef-ng-subscription.user-logins-table.show-dealer-column': boolean

  /**
   * Flag for showing BUKRFI ID column in subscription user logins table
   */
  'modules.ef-ng-subscription.user-logins-table.show-bukrfi-column': boolean

  /**
   * If set to true, users list from BI is fetched from key DealerSubscription_UserInvoiceLogins
   */
  'modules.ef-ng-subscription.user-logins-table.user-invoice-logins': boolean

  /**
   * Used to enable owner change flow for certain types of contacts.
   */
  'modules.ef-owner-change.type-list': string[]

  /**
   * Used to specify contact status list to be used for owner change flow requirements.
   */
  'modules.ef-owner-change.status-list': string[]

  /**
   * Used to specify number of dealers on a certain environment.
   * This allows us to specify the total count of dealers that should be available for selection in frame of owner change flow,
   * otherwise, pagination kicks in and request only returns 10.
   */
  'modules.ef-owner-change.dealer-count': number

  /**
   * Default value for dealer approval toggle on campain create form, if missing default is false
   */
  'modules.ef-ng-marketing.dealer-approval': boolean

  /**
   * Define if brand is required
   */
  'modules.ef-ng-marketing.brand-optional': boolean
  /**
  * Show polite flag toggle on campaing details, default false
  */
  'modules.ef-ng-marketing.polite-flag': boolean
  /**
   * A list of overview tabs.
   */
  'modules.ef-ng-ffu-admin.overview-tabs': Array<(
    'source' | 'subSource' | 'channel' |
    'group' | 'campaign' | 'event' |
    'formType' | 'notInterestedReasons' | 'disqualificationReasons')>

  /**
   * Enables 2FA on login if set.
   */
  'modules.hub-login.enabled-2fa': boolean

  /**
   * When set to true, authority URL with tenantId is used (user accounts from your tenant only). Otherwise, default authority is used (user accounts from any tenant and personal Microsoft accounts).
   */
  'modules.msal-integration.user-login.single-tenant': boolean

  /**
   * Unique Application (client) ID assigned to app by Azure AD when the app was registered
   */
  'modules.msal-integration.user-login.client-id': string

  /**
   * Strings array of scopes ("permissions") - any aspect of a resource that an access token grants rights
   */
  'modules.msal-integration.user-login.scopes': string[]

  /**
   * Available currencies for promotions admin
   */
  'modules.promotions-admin.currencies': string[]

  /**
   * Fixed VAT value for promotions admin
   */
  'modules.promotions-admin.vat': number

  /**
   * Flag used to hide certain features for carmarket
   */
  'modules.promotions-admin.is-carmarket': boolean

  /**
   * Country abbreviation for fetching price channel used on efit, used on pcd hr also for display logic on features (e.g. HR)
   */
  'modules.sm-offer-contract-flow-dialog.channel-country': string

  /**
   * Currency abbreviation for price form fields on offer/contract flow (e.g. CHF)
   * @deprecated Use hub.localization.currency instead.
   */
  'modules.sm-offer-contract-flow-dialog.currency': string

  /**
   * SAL-1947 Is expiration date mandatory or not on price preview
   */
  'modules.sm-offer-contract-flow-dialog.expiration-date-mandatory': boolean

  /**
   * SAL-2491 Is expiration date empty initialy or not on price preview
   */
  'modules.sm-offer-contract-flow-dialog.expiration-date-empty': boolean

  /**
   * SAL-2407 Is price vat reduction shown or not
   */
  'modules.sm-offer-contract-flow-dialog.price-vat-reduction': boolean

  /**
   * SAL-2407 Is tax difference shown or not
   */
  'modules.sm-offer-contract-flow-dialog.tax-difference': boolean

  /**
   * Currency locale used for formatting on offer/contract flow (e.g. de-CH)
   * @deprecated Use hub.localization.currency-locale instead.
   */
  'modules.sm-offer-contract-flow-dialog.currency-locale': string

  /**
   * The default channel value for offer/contract flow, used on PCD HR
   */
  'modules.sm-offer-contract-flow-dialog.default-channel-value': string

  /**
   * The default warranty value for offer/contract flow, used on PCD HR
   */
  'modules.sm-offer-contract-flow-dialog.default-warranty-value': string

  /**
   * Default language for offer/contract flow paymentTerms, warranty etc., used on PCD HR
   */
  'modules.sm-offer-contract-flow-dialog.default-language': string

  /**
   * Disables vehicle overview/details on vehicle card while in offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.disable-duplicate-offer-vehicle-overview': boolean

  /**
   * Shows full vehicle overview/details on vehicle card while in offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.show-vehicle-details': boolean

  /**
   * Flag that enables warranty sections on price calculation
   */
  'modules.sm-offer-contract-flow-dialog.show-warranty': boolean

  /**
   * Hides actions on offer-contract-flow-complete view when offer/contract is created
   */
  'modules.sm-offer-contract-flow-dialog.hide-on-complete-buttons': boolean

  /**
   * Url for contact service used in offer/contract flow components
   */
  'modules.sm-offer-contract-flow-dialog.inside-url': string

  /**
   * Uses alternative pcd flow for editing contract
   */
  'modules.sm-offer-contract-flow-dialog.pcd-edit-contract': boolean

  /**
   * If option validation on offer contract dashboard for contracts is triggered or not
   */
  'modules.sm-offer-contract-flow-dialog.validate-options': boolean

  /**
  * Shows or hides bcc field in email editor in offer/contract flow
  */
  'modules.sm-offer-contract-flow-dialog.bcc': boolean

  /**
   * Flag for vat rate on add accessory modal on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.show-accessory-vat-rate': boolean

  /**
   * Flag for enabling saving accessory without price
   */
  'modules.sm-offer-contract-flow-dialog.accessory-no-price': boolean

  /**
   * Gdpr reminder flag on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.show-gdpr-reminder': boolean

  /**
   * Config for showing or hiding leasing section on new offer/contract flow.
   */
  'modules.sm-offer-contract-flow-dialog.show-leasing': boolean

  /**
   * Flag that tells offer/contract flow that hr price calculation component is used
   */
  'modules.sm-offer-contract-flow-dialog.use-hr-price-calculation': boolean

  /**
   * Flag that enables second contact selection on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.use-second-contact': boolean

  /**
   * Flag that enables simple trade in on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.use-simple-trade-in': boolean

  /**
   * Flag that enables trade in on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.trade-in': boolean

  /**
   * Flag that determines if extended warranty price is mandatory, if it's not mandatory
   * null can also be added and other extended warranty data along with price 0 will be saved
   */
  'modules.sm-offer-contract-flow-dialog.extended-warranty-mandatory-price': boolean

  /**
   * Business contact validation data for offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.validation-override-data.business': string[]

  /**
   * Flag that shows customer satisfaction survey checkbox on price caluculation screen on offer/contract creation
   */
  'modules.sm-offer-contract-flow-dialog.show-customer-satisfaction-survey': boolean

  /**
   * Flag for showing date of downpayment on price caluculation screen on offer/contract creation
   */
  'modules.sm-offer-contract-flow-dialog.show-date-of-down-payment': boolean

  /**
   * If service contracts price list feature is enabled or not #SAL-2279
   */
  'modules.sm-offer-contract-flow-dialog.service-contracts-price-list': boolean

  /**
   * Flag that enables custom validation which is defined in config for bussiness and personal contacts on offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.validation-override-data.enabled': boolean

  /**
   * Personal contact validation data for offer/contract flow
   */
  'modules.sm-offer-contract-flow-dialog.validation-override-data.personal': string[]

  /**
   * Flag to control hiding contact preview for importer on task management and tasks dashboard widget
   */
  'modules.task-management.hide-importer-contact-preview': boolean

  /**
   * Feature that enables special PDI options handling, displaying 0 instead of PDI option price and
   * removing them from calculations
  */
  'modules.vp-configurator-component.pdi-handling': boolean

  /**
   * Feature that adds foreign dealer stock to search results of vehicle lists on configurator.
   * https://emilfreygroup.atlassian.net/browse/VP-2629
  */
  'modules.vp-configurator-component.search-result-vehicle-list.foreign-dealer-stock': boolean

  /**
   * Flag that enables build year on vehicle configurator component
   * https://emilfreygroup.atlassian.net/browse/SAL-2614
   */
  'modules.vp-configurator-component.show-build-year': boolean

  /**
   * Flag that enables manual color selection on color dropdown on vehicle configurator component
   * https://emilfreygroup.atlassian.net/browse/SAL-4725
   */
  'modules.vp-configurator-component.manual-color-selection': boolean

  /**
   * Flag that enables manual interior color selection on interior color dropdown on vehicle configurator component
   * https://emilfreygroup.atlassian.net/browse/SAL-4725
   */
  'modules.vp-configurator-component.manual-interior-color-selection': boolean

  /**
   * Flag that enables trade in vehicle warning on o/c flow
   * https://emilfreygroup.atlassian.net/browse/VP-2327
   */
  'modules.vp-configurator-component.enable-trade-in-warning': boolean

  /**
   * Flag that enables submit warning on o/c flow
   * https://emilfreygroup.atlassian.net/browse/VP-2327
   */
  'modules.vp-configurator-component.enable-submit-warning': boolean

  /**
   * Flag that enables submit warning for country on o/c flow
   * https://emilfreygroup.atlassian.net/browse/SAL-3212
  */
  'modules.vp-configurator-component.enable-submit-warning-country': boolean

  /**
   * Flag that enables flow where you first have to visit configurator on edit contract, copy contract or contract from offer
   * https://emilfreygroup.atlassian.net/browse/VP-3980
   */
  'modules.vp-configurator-component.first-configurator-flow': boolean

  /**
   * Flag that hides clear all filters button on model info configurator
   */
  'modules.vp-configurator-component.clear-filters-button': boolean

  /**
   * Feature that allows vehicle details to display raw efit values without any formatting, translations or changes.
   * https://emilfreygroup.atlassian.net/browse/VP-2122
   */
  'modules.vp.vehicle-details-efit-values': boolean

  /**
  * Enable or disable Keycloak on environment
  * */
  'hub.auth.keycloak.enabled': boolean

  /**
  * Url to Keycloak auth page (redirection from hub login screen)
  * */
  'hub.auth.keycloak.url': string

  /**
  * Keycloak Realm value - realm manages a set of users, credentials, roles, and groups
  * */
  'hub.auth.keycloak.realm': string

  /**
  * Keycloak client id - url's and other connections/settings for client
  * */
  'hub.auth.keycloak.client-id': string

  /**
  * client id configuration for localhost development - user will be redirected back to localhost on Keycloak redirecting
  * */
  'hub.auth.keycloak.client-id-localhost': string

  /**
  * Refresh token interval - value in seconds for checking validity of KC token (and to check if token expires within defined time)
  * */
  'hub.auth.keycloak.refresh-token-interval': string

  /**
   * Enable or disable buyer
   * https://emilfreygroup.atlassian.net/browse/VP-2397
   */
  'modules.vp-form-components.choose-configuration-salesman': boolean

  /**
   * Determines the validation pattern for any VIN input field.
   */
  'modules.vp-form-components.vin-pattern': string

  /**
   * Country abbreviation which is used for some country specific features on stock overview
   */
  'modules.vp-stock-overview-component.country': string

  /**
   * Currency abbreviation used on stock overview price fields
   */
  'modules.vp-stock-overview-component.currency': string

  /**
   * Currency locale used on stock overview for currency formatting
   */
  'modules.vp-stock-overview-component.currency-locale': string

  /**
   * Displayed filters' config for stock overview
   */
  'modules.vp-stock-overview-component.displayed-filters': string[]

  /**
   * Displayed columns config for stock overview, this is used only for column visibility which can be shown on table three dot menu
   */
  'modules.vp-stock-overview-component.displayed-columns': string[]

  /**
   * Flag which enables body type column to be default in 'stock vehicles' view
   * https://emilfreygroup.atlassian.net/browse/SAL-2435
   */
  'modules.vp-stock-overview-component.enable-default-body-type-column': boolean

  /** Flag that enables estimated arrival time column to be default in 'stock vehicles' view
   * https://emilfreygroup.atlassian.net/browse/SAL-2435
   */
  'modules.vp-stock-overview-component.enable-default-eta-column': boolean

  /**
   * Flag that enables flexible column to be default in 'stock vehicles' view
   * https://emilfreygroup.atlassian.net/browse/SAL-2435
   */
  'modules.vp-stock-overview-component.enable-default-flex-column': boolean

  /**
   * Flag for equipment package model on stock overview
   */
  'modules.vp-stock-overview-component.equipment-package-model': boolean

  /**
   * Flag which enables narrow column width for some columns on stock list
   * https://emilfreygroup.atlassian.net/browse/SAL-2564
   */
  'modules.vp-stock-overview-component.enable-column-width': boolean

  /**
   * Flag used for hiding energy etiquette on stock overview table row actions
   */
  'modules.vp-stock-overview-component.hide-energy-etiquette': boolean

  /**
   * Flag which hides purchase filter section
   */
  'modules.vp-stock-overview-component.hide-purchase-filters': boolean

  /**
   * Personal vehicles co2 limit which is used for co2 kpi display logic on stock overview
   */
  'modules.vp-stock-overview-component.personal-vehicles-co2-limit': number

  /**
   * Rows per page that are possible on stock overview table, if none provided default is used
   */
  'modules.vp-stock-overview-component.rows-per-page': number[]

  /**
   * Flag for brand network stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-brand-network-stock': boolean

  /**
   * Days on stock filter section flag
   */
  'modules.vp-stock-overview-component.show-days-on-stock-overview': boolean

  /**
   * Flag for dealer stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-dealer-stock': boolean

  /**
   * Flag for dealer group stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-dealer-group-stock': boolean

  /**
   * Flag for dealer efit stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-efit-dealer-stock': boolean

  /**
   * Flag for importer efit stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-efit-importer-stock': boolean

  /**
   * Flag for efit network stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-efit-network-stock': boolean

  /**
   * Flag for equipment tooltip on stock overview
   */
  'modules.vp-stock-overview-component.show-equipment-tooltip': boolean

  /**
   * Flag for foreign dealer stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-foreign-dealer-stock': boolean

  /**
   * Flag for importer stock tab, check made in combination with acl
   */
  'modules.vp-stock-overview-component.show-importer-stock': boolean

  /**
   * Flag for order number column
   */
  'modules.vp-stock-overview-component.show-order-number-column': boolean

  /**
   * Flag for checking if stock vehicle actions check is enabled or disabled,
   * if it's enabled only vehicles which are not in status order draft, customer, order or
   * vehicles which do not have transaction importing and importing error.
   *
   * This check is usually disabled (true) on non CH countries due to difference in logic.
   */
  'modules.vp-stock-overview-component.stock-vehicle-action-check': boolean

  /**
   * Flag for unnormalized filters that aren't translated (body type, fuel etc)
   */
  'modules.vp-stock-overview-component.use-unnormalized-filters': boolean

  /**
   * Vans co2 limit which is used for co2 kpi display logic
   */
  'modules.vp-stock-overview-component.vans-co2-limit': number

  /**
   * Flag which enables vehicle details view directly from stock list
   * https://emilfreygroup.atlassian.net/browse/SAL-2305
   */
  'modules.vp-stock-overview-component.vehicle-details-button': boolean

  /**
   * Flag for enabling vehicle actions on Efit importer stock overview
   */
  'modules.vp-stock-overview-component.show-efit-importer-stock-vehicle-actions': boolean

  /**
   * Flag for using model info configurator for HR/SI
   */
  'modules.vp-stock-overview-component.use-model-info-configurator': boolean

  /**
   * Flag that disables stock check if all details tabs should be visible for all stock types
   */
  'modules.vp-vehicle-details-component.disable-stock-check': boolean

  /**
   * Currency abbreviation for stock vehicle details
   */
  'modules.vp-vehicle-details-component.vehicle-details-config-currency': string

  /**
   * Max published vehicle title length on marketplace
   */
  'modules.vp-vehicle-details-component.publish-tab-max-title-characters': number

  /**
   * Max comment characters in edit tab for quick edit data
   */
  'modules.vp-vehicle-details-component.edit-tab-max-comment-characters': number

  /**
   * Currency abbreviation for stock vehicle details
   */
  'modules.vp-vehicle-details-info.currency': string

  /**
  * Availability check for stock vehicle details
  */
  'modules.vp-vehicle-details-info.availability-check': boolean

  /**
   * Currency locale used for currency formatting on stock vehicle details view
   */
  'modules.vp-vehicle-details-info.currency-locale': string

  /**
   * Flag which hides b2b price on vehicle details view
   */
  'modules.vp-vehicle-details-info.hide-b2b-price': boolean

  /**
   * Flag which hides dcmi statuses on vehicle details view
   */
  'modules.vp-vehicle-details-info.hide-dcmi-statuses': boolean

  /**
   * Flag which hides lcdv code on vehicle details view
   */
  'modules.vp-vehicle-details-info.hide-lcdv-code': boolean

  /**
   * Flag which enables latest appraisal for price on vehicle details view
   */
  'modules.vp-vehicle-details-info.latest-appraisal': boolean

  /**
   * Flag which shows efit additional details on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-efit-additional-details': boolean

  /**
   * Flag which enables reservations on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-reservations': boolean

  /**
   * Flag which enables commisions on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-commisions': boolean

  /**
   * Flag that enables dealer info on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-dealer-info': boolean

  /**
   * Flag for efit days section on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-efit-days-on-stock': boolean

  /**
   * Flag which shows efit colors on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-efit-colors': boolean

  /**
   * Flag which shows efit options on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-efit-options': boolean

  /**
   * Flag which shows efit code on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-efit-code': boolean

  /**
   * Flag which shows offer/contract buttons on vehicle info dialog
   */
  'modules.vp-vehicle-details-info.show-oc-buttons-on-info-dialog': boolean

  /**
   * Flag which shows vehicle capacity on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-vehicle-capacity': boolean

  /**
   * Flag which shows max vehicle weight on vehicle details view
   */
  'modules.vp-vehicle-details-info.show-weight-max': boolean

  /**
   * Flag to control displaying of current date prefilled on lead entry activities
   */
  'modules.ffu.activity-date-prefilled': boolean

  /**
   * Flag to control displaying of current time prefilled on lead entry activities
   */
  'modules.ffu.activity-time-prefilled': boolean

  /**
   * Brand name used for redirection
   */
  'modules.ffu.brand': string

  /**
   * Flag to control code ID validation when adding after sales or used vehicle
   */
  'modules.ffu.code-id-non-mandatory': boolean

  /**
   * List of fields that should have currency on lead action details
   */
  'modules.ffu.currency-fields': string[]

  /**
   * Flag to control displaying of customer alternative location on dealer search
   */
  'modules.ffu.customer-alternative-location': boolean

  /**
   * Flag to control displaying date created as default filter on lead overview
   */
  'modules.ffu.date-created-filter-default': boolean

  /**
   * Flag to control contact fast edit on contact widget
   */
  'modules.ffu.disable-contact-widget-fast-edit': boolean

  /**
   * Flag to control displaying of gdr section on lead entry
   */
  'modules.ffu.disable-gdpr-business': boolean

  /**
   * Flag to control contact change on lead details
   */
  'modules.ffu.disable-lead-contact-change': boolean

  /**
   * Convention for displaying dealer name
   */
  'modules.ffu.dealer-name-convention': string

  /**
   * Flag to control displaying of profession select on lead contact
   */
  'modules.ffu.disable-profession-title': boolean

  /**
   * Flag to control displaying of due date confirmation button
   */
  'modules.ffu.due-date-confirmation': boolean

  /**
   * SubSource for which the due date confirmation button will be displayed
   */
  'modules.ffu.due-date-confirmation-sub-source': boolean

  /**
   * Array of external dealer ids for which the duplicate leads notification is displayed
   */
    'modules.ffu.duplicate-leads-notification.dealer-ids': number[]

  /**
   * Flag to control displaying of email widget on lead details
   */
  'modules.ffu.email-widget': boolean

  /**
   * Name of enviroment
   */
  'modules.ffu.environment': string

  /**
   * Controls displaying of button for quick action on lead details
   */
  'modules.ffu.fallback-vehicle-id': number

  /**
   * List of fields that should have translation on lead action details
   */
  'modules.ffu.fields-as-translation': string[]

  /**
   * List of permitted activities on importer stock vechicle
   */
  'modules.ffu.importer-stock-permitted-activities': string[]

  /**
   * List of fields that should have formatted value on lead action details
   */
  'modules.ffu.formatted-number-fields': string[]

  /**
   * Flag to control displaying of handler and fleet info on lead definition
   */
  'modules.ffu.handler-and-fleet-info': boolean

  /**
   * List of filters that should be hidden
   */
  'modules.ffu.hidden-filters': string[]

  /**
   * Flag to control hiding contact preview for importer on lead overview
   */
  'modules.ffu.hide-importer-contact-preview': boolean

  /**
   * Flag to control hiding vin field on trade in vehicle on lead entry and lead details
   */
  'modules.ffu.hide-vin-field-on-trade-in': boolean

  /**
   * Controls setting of qualification dealership on lead overview
   */
  'modules.ffu.kkc-dealership-cdb-id': number

  /**
   * Flag to control lead definition filtering on lead details
   */
  'modules.ffu.lead.lead-definition-filtering': boolean

  /**
   * Flag to control displaying of service action button on lead entry according to kkc role
   */
  'modules.ffu.lead-entry.kkc-only-after-sales': boolean

  /**
   * Flag to control displaying of parts action button on lead entry
   */
  'modules.ffu.lead-entry.parts-action-enabled': boolean

  /**
   * Lead entry item which is being pre-filled
   */
  'modules.ffu.lead-entry.pre-fill.0.name': string

  /**
   * Role for which the item should be pre-filled
   */
  'modules.ffu.lead-entry.pre-fill.0.role': string

  /**
   * Value that is being pre-filled, has to be item name in camel case
   */
  'modules.ffu.lead-entry.pre-fill.0.value': string

  /**
   * Column name on lead overview table
   */
  'modules.ffu.lead-overview-columns.0.name': string

  /**
   * Flag to control displaying of column on lead overview table
   */
  'modules.ffu.lead-overview-columns.0.displayed': boolean

  /**
   * Column sticky property on lead overview table
   */
  'modules.ffu.lead-overview-columns.0.sticky': string

  /**
   * Column width property on lead overview table
   */
  'modules.ffu.lead-overview-columns.0.width': number

  /**
   * Brand name property for call attempts on lead details
   */
  'modules.ffu.call-attempts.0.brand-name': string

  /**
   * Number of attempts for call attempts on lead details
   */
  'modules.ffu.call-attempts.0.number': number

  /**
   * Default value of attempts for call attempts on lead details
   */
  'modules.ffu.default-number-of-call-attempts': number

  /**
   * Flag to control using of old url for lead details
   */
  'modules.ffu.old-lead-details-url': boolean

  /**
   * Flag to control displaying of cp lead widget on dashboard page
   */
  'modules.ffu.old-lead-widgets': boolean

  /**
   * Flag to control displaying of cp task widget on dashboard page
   */
  'modules.ffu.old-task-widget': boolean

  /**
   * List of fields that should have value as percentage on lead action details
   */
  'modules.ffu.percentage-fields': string[]

  /**
   * Flag to control sales flow integration on lead action
   */
  'modules.ffu.sales-flow-integration': boolean

  /**
   * Default source on lead entry
   */
  'modules.ffu.settings.default-source': number

  /**
   * Default subsource on lead entry
   */
  'modules.ffu.settings.default-sub-source': number

  /**
   * Group dealer id sent within request on lead entry and ticket
   */
  'modules.ffu.settings.group.dealer.id': number

  /**
   * Group dealer name sent within request on lead entry and ticket
   */
  'modules.ffu.settings.group.dealer.name': string

  /**
   * Group dealer visibility property sent in request on lead entry and ticket
   */
  'modules.ffu.settings.group.dealer.visible': boolean

  /**
   * Group importer id sent within request on lead entry and ticket
   */
  'modules.ffu.settings.group.importer.id': number

  /**
   * Group importer name sent within request on lead entry and ticket
   */
  'modules.ffu.settings.group.importer.name': string

  /**
   * Group importer visibility property sent in request on lead entry and ticket
   */
  'modules.ffu.settings.group.importer.visible': boolean

  /**
   * Flag to control preventing SLA counter to go to negative value
   */
  'modules.ffu.sla.expire-to-zero': boolean

  /**
   * Flag to control displaying of SLA column on lead overview table
   */
  'modules.ffu.sla.show-sla': boolean

  /**
   * Key of the field that should have suffix on lead action details
   */
  'modules.ffu.suffix-fields.0.key': string

  /**
   * Value of the suffix for field that should have suffix on lead action details
   */
  'modules.ffu.suffix-fields.0.suffix': string

  /**
   * Flag to control displaying of SLA qualification column on lead overview table
   */
  'modules.ffu.sla.show-sla-qualification': boolean

  /**
   * Delay in milliseconds after which remaining time for SLA is checked
   */
  'modules.ffu.sla.timer': number

  /**
   * Flag to control displaying of 24 hours SLA column on lead overview table
   */
  'modules.ffu.sla24.show-sla': boolean

  /**
   * Delay in milliseconds after which remaining time for 24 hours SLA is checked
   */
  'modules.ffu.sla24.timer': number

  /**
   * Brands on which 24 SLA should be displayed
   */
  'modules.ffu.sla24.0.brand': string

  /**
   * Flag to control clickability of vehicle code id / vin for vehicle stock on lead action details
   */
  'modules.ffu.stock-vehicle-link': boolean

  /**
   * Ticket model id property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.model.id': number

  /**
   * Ticket model brand id property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.model.brand-id': number

  /**
   * Ticket model brand name property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.model.brand-name': string

  /**
   * Ticket model name property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.model.name': string

  /**
   * Ticket source id property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.source.id': number

  /**
   * Ticket source name property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.source.name': string

  /**
   * Ticket subsource id property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.sub-source.id': number

  /**
   * Ticket subsource name property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.sub-source.name': string

  /**
   * Ticket channel id property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.channel.id': number

  /**
   * Ticket channel name property sent in request for lead creation on ticket
   */
  'modules.ffu.ticket.channel.name': string

  /**
   * Flag to control if link for trade in offer should navigate to sales view
   */
  'modules.ffu.use-oclink-for-trade-in': boolean

  /**
   * If set to true, unauthorised user will have some limitations regarding emails, conversations won't be created for new leads. On existing leads, button for sending an email will be disabled.
   */
  'modules.hub-common.email.unauthorised-limitations': boolean

  /**
   * This config enables lead qualification if no vehicle is added to lead, RS case with ticketing feature
   */
  'modules.ffu.qualification-without-vehicle': boolean

  /**
   * Group id for EF dealers, when fetching them from CDB backend, used for ABO activity
   */
  'modules.ffu.ef-dealers.group.id': number

  /**
   * Flag which controls if vehicle configuration will be copied when duplicating offer
   */
  'modules.sm-offer-contract-flow-dialog.copy-configurator-vehicle': boolean

  /**
  * Flag wich controls if leasing flow will be visible in pdf contract view
  */
  'modules.ef-sm-offer-contract-list.show-leasing-flow-button': boolean

  /**
  * Property which sets default period months on offer contract list
  */
  'modules.ef-sm-offer-contract-list.period-months': number

  /**
   * Flag which controls fetching dealer for different countries
   */
  'modules.sales-declaration.dealer.ce': boolean

  /**
   * Flag to V360 overview tab visability
   */
  'modules.v360.vehicle-details.tabs.visibility.overview': boolean

  /**
   * Flag to V360 details tab visibility
   */
  'modules.v360.vehicle-details.tabs.visibility.details': boolean

  /**
   * Flag to V360 activity log tab visability
   */
  'modules.v360.vehicle-details.tabs.visibility.activity-log': boolean

  /**
   * Flag to V360 details tab visibility of retail switcher
   */
  'modules.v360.vehicle-details.tab.visibility.retail': boolean

  /**
   * Flag to V360 details tab visibility of wholesale switcher
   */
  'modules.v360.vehicle-details.tab.visibility.wholesale': boolean

  /**
   * Flag to V360 sidebar wholesale customer owner and driver widgets visibility
   */
  'modules.v360.sidebar.wholesale-widget.visibility': boolean

  /**
   * For zip field allow to validate alphanumeric values
   */
  'modules.customer-profile.views.contact.zip-alphanumeric': boolean

  /**
   * URL for QR instructions on appraisal search
   */
  'modules.vp-trade-in-dialog.qr-instructions-link': string

  /**
   * Number of seconds to wait for FFU backend to create lead entity after
   * creating an offer copy with a new lead
   */
  'modules.sm-offer-contract-flow-dialog.copy-offer.seconds-waiting': number

  /**
   * External application link name
   */
  'modules.ef-hub-external-applications.link.0.name': string

  /**
   * External application link url
   */
  'modules.ef-hub-external-applications.link.0.url': string

  /**
   * Multiple languages option in price calculation in OC flow
   */
  'modules.sm-offer-contract-flow-dialog.price-calculation-item-languages': boolean

  /**
   * Visibility of system ID column in Offer and Contract tables
   */
  'modules.sales-overview.tables.system-id-column-visibility': boolean

  /**
   * CO2 limit for personal vehicles used to figure out if a vehicle is over
   * or under the limit in emmission widget on vehicle info tab
   */
  'modules.vp-vehicle-details-info-tab.emmission-widget.personal-vehicle-co2-limit': number

  /**
   * CO2 limit for commercial vehicles used to figure out if a vehicle is over
   * or under the limit in emmission widget on vehicle info tab
   */
  'modules.vp-vehicle-details-info-tab.emmission-widget.commercial-vehicle-co2-limit': number

  /**
   * Flag which is used to display vehicle prices of a new vehicle whilst
   * accessing the details of a used vehicle
   */
  'modules.vp-vehicle-details-info-tab.show-additional-vehicle-prices': boolean

  /**
   * Visibility of B2C File Upload button for ef-sm-leasing-flow
   */
  'modules.ef-sm-leasing-flow.show-b2c-button-upload': boolean

  /**
   * Visibility of B2C Antrag Handover button for ef-sm-leasing-flow
   */
  'modules.ef-sm-leasing-flow.show-b2c-button-handover': boolean

  /**
   * Minimum string length for which appraisal/vehicle search is triggered
   */
  'modules.sm-appraisal-vehicle-search.min-search-length': number

  /**
   * Visibility of B2C Antrag Handover button for Commercial Antrag in ef-sm-leasing-flow
   */
  'modules.ef-sm-leasing-flow.show-b2c-button-handover-commercial': boolean

  /**
   * Show GDPR module when selecting the contact in offer/contract flow.
   */
  'modules.sm-offer-contract-flow-dialog.new-gdpr-module': boolean

  /**
   * used to support 7digits numbers because 6-digit customer-Nrs are running out
   */
  'modules.customer-profile.contacts.show-alphanumeric-ids': boolean

  /**
   * Flag to V360 documents tab visability
   */
  'modules.v360.vehicle-details.tabs.visibility.documents': boolean

  /**
   * Hide second contact column by default.
   */
  'modules.sm-offer-contract-flow-dialog.second-contact-hidden-column': boolean

  /**
   * Pattern for indetification number. If the config is present - this pattern is set to the field, if not - only the number.
   * Be aware to include a-žA-Ž as allowed chars. Possible value is ^[a-žA-Ž0-9]*$.  Should be taken into account that the char index range for a-žA-Ž is 65-382. Here the list of char
   * @see https://en.wikipedia.org/wiki/List_of_Unicode_characters
   */
  'modules.customer-profile.views.contact.identification-number-regex': string

  /**
   * If the config is present the max length is set, if not - it is 8 by default (as it was before)
   */
  'modules.customer-profile.views.contact.identification-number-max-length': boolean

  /**
   * if the config is present the min length is set, if not - no restriction for it
   */
  'modules.customer-profile.views.contact.identification-number-min-length': boolean

  /**
   * Display second contact column in contracts table
  */
  'modules.sm-offer-contract-flow-dialog.display-second-contact-column': boolean

  /* Do not require EU tax for entrepeneurs
   */
  'modules.general-contact-info.self-employed.eu-tax-not-required': boolean

  /**
   * Enable copy offer flow in CE
   */
  'modules.vp-configurator-component.copy-offer-efit': boolean

  /**
   * Enable history tooltip on price overview widget
   */
  'modules.vp-vehicle-details-info-tab.price-overview-widget.history-tooltip-enabled': boolean

  /**
   * Hide VAT rate for accessories on price calculation in OC flow
   */
  'modules.sm-offer-contract-flow-dialog.price-calculation-hr.accessories.hide-vat-rate': boolean

  /**
   * Hide reduced VAT rate on price calculation in sales flow
   */
  'modules.sm-offer-contract-flow-dialog.hide-vat-reduced-rate': boolean

  /**
   * Hide special VAT rate on price calculation in sales flow
   */
  'modules.sm-offer-contract-flow-dialog.hide-vat-special-rate': boolean

  /**
   * Show publish message on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.show-publish-message': boolean

  /**
   * Enable vehicle data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.enable-vehicle-data-form': boolean

  /**
   * Enable technical data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.enable-technical-data-form': boolean

  /**
   * Enable ad data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.enable-ad-data-form': boolean

  /**
   * Show vehicle data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.show-vehicle-data-form': boolean

  /**
   * Show technical data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.show-technical-data-form': boolean

  /**
   * Enable ad data form on edit tab in vehicle details
   */
  'modules.sm-vehicle-details-edit.show-ad-data-form': boolean

  /**
   * Default refresh interval used in vehicle details publish tab for refreshing publish status of market ads
   */
  'modules.sm-vehicle-details-publish.market-ads-refresh-interval': number

  /**
   * Default offer validity days fallback in case no value set on pp for dealer
   */
  'modules.sm-offer-contract-flow-dialog.default-offer-validity-days': number

  /**
   * Period in days for setting offer validity where it's not mandatory (SI)
   */
  'modules.sm-offer-contract-flow-dialog.expiration-date-empty-period': number

  /**
   * Maximum option name length on configurator views
   */
  'modules.vp-configurator-component.option-name-max-length': number

  /**
   * Show CE reservations widget on vehicle details info tab
   */
  'modules.vp-vehicle-details-info.show-ce-reservations-widget': boolean

  /**
   * Default service portal mail sender name
   */
  'modules.service-portal.notifications.email.default-sender.name': string

  /**
   * Default service portal mail sender email address
   */
  'modules.service-portal.notifications.email.default-sender.address': string

  /**
   * Flag indicating whether special customer vehicle orders are enabled upon contract signing.
   */
  'modules.ef-sm-offer-contract-list.enable-special-customer-order': boolean
  /**
   * Flag indicating whether waiting on import text is visible.
   */
  'modules.ef-sm-offer-contract-list.waiting-on-import-text': boolean
  /**
   * Flag indicating whether finalize contract button is enabled.
   */
  'modules.ef-sm-offer-contract-list.finalize-contract-button': boolean
  /**
   * Flag indicating whether countersign subfilter is enabled.
   */
  'modules.ef-sm-offer-contract-list.countersign-subfilter': boolean
  /**
  * Enable omikron address autocomplete on new c360 as opposed to Google address autocomplete
  */
  'modules.customer-profile.c360.omikron-autocomplete-address': boolean

  /**
  * Countries which can use omikron autocomplete - fallback to Google address autocomplete for others
  */
  'modules.customer-profile.c360.omikron-autocomplete-address.countries': string[]

  /**
   * Flag indicating whether CAF number should be displayed for importer users.
   */
  'modules.vp-stock-overview-component.importer.show-caf-number': boolean

  /**
   * Flag indicating whether vehicle order data should be displayed on vehicle details.
   */
  'modules.vp-vehicle-details-info-tab.show-efit-order-data': boolean

  /**
   * Flag indicating whether new dealer creation flow should be enabled.
   */
  'modules.partner-profile.features.dealer-creation-new-flow': boolean

  /**
   * Flag indicating whether occasion program widget should be displayed on vehicle details (SAL-7556).
   */
  'modules.vp-vehicle-details-info-tab.show-occasion-program-widget': boolean
}

export type HubConfigurationKey = keyof HubConfiguration
