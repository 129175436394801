import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { StorageService } from '@inside-hub-app/hub-storage'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, flatMap, map, tap, take } from 'rxjs/operators'
import { Environment } from '../environments'
import { ENVIRONMENT, STORAGE_PREFIX } from '../injection-tokens'
import { AuthService } from './auth.service'
import * as _ from 'lodash'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { NGXLogger } from 'ngx-logger'
import { SaasPermission, PartnerSubscription, Package } from '@inside-hub-app/hub-authorization-client'

export interface AccessRight {
  id: number
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  product: string
  role: string
  permission: string
}

export interface AclRequestQueryParams {
  role: string
  dealerId: number
  platform: string
}

export interface PermittedResult {
  result: boolean
}

export interface PermittedResource {
  rsid: string
  scopes: string[]
}

export enum Parameters {
  RESPONSE_MODE = 'response_mode',
  PERMISSION = 'permission',
  AUDIENCE = 'audience'
}

export enum ResponseMode {
  DECISION = 'decision',
  PERMISSIONS = 'permissions'
}

interface Permission {
  allowed: boolean
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
}

enum SubscriptionPackage {
  CARMARKET = 'carmarket'
}

@Injectable({
  providedIn: 'root'
})

export class PermissionService {
  private readonly apiUrl
  private acl: AccessRight[] = undefined
  saasPermissions: SaasPermission[] = []
  permissions: Permission | Record<string, never> = {}
  currentPartnerPackages: Package[]

  constructor (
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    @Inject(STORAGE_PREFIX) private readonly storagePrefix: string,
    private readonly authService: AuthService,
    private readonly hubConfiguration: HubConfigurationService,
    private readonly logger: NGXLogger
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  public refreshAcl (
    platforms: AclRequestQueryParams[]
  ): Observable<AccessRight[]> {
    // first remove current acl
    return this.removeAcl().pipe(
      // fetch new acl
      flatMap(_ => {
        if (platforms.length > 0) {
          const platformPermissions$: Array<Observable<AccessRight[]>> = []
          platforms.forEach(aclParams => {
            platformPermissions$.push(
              this.fetchAcl(
                aclParams.role,
                aclParams.dealerId,
                aclParams.platform
              )
            )
          })
          return forkJoin(platformPermissions$)
        } else {
          console.warn(
            '____EF-NG-PP-CLIENT____: List of requested platforms is empty.'
          )
          return of([])
        }
      }),

      // store it
      flatMap(platformRights => {
        platformRights.forEach(setOfRights => {
          if (!Array.isArray(this.acl)) {
            this.acl = []
          }

          this.addPermissions(setOfRights)
        })

        return this.storage
          .set(this.storagePrefix + 'acl', this.acl)
          .pipe(map(_ => this.acl))
      })
    )
  }

  private addPermissions (setOfNewRights: AccessRight[]): void {
    if (Array.isArray(setOfNewRights) && (setOfNewRights.length > 0)) {
      if (this.acl.length > 0) {
        setOfNewRights.forEach(newRight => {
          const existingAccessRight = this.acl.find(
            ar =>
              ar.permission === newRight.permission &&
              ar.product === newRight.product
          )

          if (existingAccessRight != null) {
            existingAccessRight.read = newRight.read || existingAccessRight.read
            existingAccessRight.create =
              newRight.create || existingAccessRight.create
            existingAccessRight.update =
              newRight.update || existingAccessRight.update
            existingAccessRight.delete =
              newRight.delete || existingAccessRight.delete
          } else {
            this.acl.push(newRight)
          }
        })
      } else {
        this.acl = [...setOfNewRights]
      }

      // Merge basic and Saas permissions
      this.mergePermissions()
    }
  }

  fetchAcl (
    role: string,
    dealerId: number,
    product: string
  ): Observable<AccessRight[]> {
    return this.http.get<AccessRight[]>(
      `${this.apiUrl}/cdb/acl/rule/list?product=${product}&role=${role}&dealerId=${dealerId}`
    )
  }

  public getAcl (): Observable<AccessRight[]> {
    if (this.acl) {
      return of(this.acl)
    }

    return this.storage.get<AccessRight[]>(this.storagePrefix + 'acl').pipe(
      map(rights => rights),
      map(rights => rights || []),
      tap(rights => {
        this.acl = rights
      })
    )
  }

  public removeAcl (): Observable<void> {
    this.acl = undefined
    return this.storage.delete(this.storagePrefix + 'acl')
  }

  removeSaasPermissions (): Observable<void> {
    this.saasPermissions = []
    return this.storage.delete(`${this.storagePrefix}saasPermissions`)
  }

  public canRead (permission: string): Observable<boolean> {
    if (!_.isEmpty(this.permissions)) {
      const canRead = this.permissions[permission]?.read
      return of(canRead)
    } else {
      return this.getPermissions().pipe(
        map(permissions => {
          const canRead = permissions[permission]?.read
          return canRead
        })
      )
    }
  }

  public canCreate (permission: string): Observable<boolean> {
    if (!_.isEmpty(this.permissions)) {
      const canCreate = this.permissions[permission]?.create
      return of(canCreate)
    } else {
      return this.getPermissions().pipe(
        map(permissions => {
          const canCreate = permissions[permission]?.create
          return canCreate
        })
      )
    }
  }

  public canUpdate (permission: string): Observable<boolean> {
    if (!_.isEmpty(this.permissions)) {
      const canUpdate = this.permissions[permission]?.update
      return of(canUpdate)
    } else {
      return this.getPermissions().pipe(
        map(permissions => {
          const canUpdate = permissions[permission]?.update
          return canUpdate
        })
      )
    }
  }

  public canDelete (permission: string): Observable<boolean> {
    if (!_.isEmpty(this.permissions)) {
      const canDelete = this.permissions[permission]?.delete
      return of(canDelete)
    } else {
      return this.getPermissions().pipe(
        map(permissions => {
          const canDelete = permissions[permission]?.delete
          return canDelete
        })
      )
    }
  }

  public isAuthorizedAction (permission?: string): Observable<boolean> {
    return this.authService.verifyToken().pipe(
      flatMap(validToken =>
        validToken && permission ? this.canRead(permission) : of(validToken)
      ),
      flatMap(isAllowed => of(!!isAllowed)),
      catchError(e => of(false))
    )
  }

  public permitted (permission: string | string[], moduleName?: string): Observable<boolean> {
    return this.getPermitted(permission, moduleName).pipe(
      map(permitted => permitted.result)
    )
  }

  public resourcePermitted (resourceName: string): Observable<string[]> {
    return this.getPermittedResource(resourceName).pipe(
      map(result => result[0]?.scopes ?? [])
    )
  }

  private getPermitted (permission: string | string[], moduleName?: string): Observable<PermittedResult> {
    let params = new HttpParams()
    params = params.set(Parameters.RESPONSE_MODE, ResponseMode.DECISION)
    if (moduleName) params = params.set(Parameters.AUDIENCE, moduleName)

    if (Array.isArray(permission)) {
      _.forEach(permission, prm => {
        let permissionVal = prm
        if (!prm.includes('#')) {
          permissionVal = `#${prm}`
        }
        params = params.append(Parameters.PERMISSION, permissionVal)
      })
    } else {
      let permissionVal = permission
      if (!permission.includes('#')) {
        permissionVal = `#${permission}`
      }
      params = params.append(Parameters.PERMISSION, permissionVal)
    }

    return this.http.post<PermittedResult>(`${this.apiUrl}/cdb/permissions`, params)
  }

  private getPermittedResource (resourceName: string): Observable<PermittedResource> {
    let params = new HttpParams()
    params = params.append(Parameters.PERMISSION, resourceName)

    return this.http.post<PermittedResource>(`${this.apiUrl}/cdb/permissions`, params)
  }

  refreshSaasPermissions (permissions: SaasPermission[], partnerSubscriptions?: PartnerSubscription[]): void {
    this.removeSaasPermissions().pipe(take(1)).subscribe()
    this.saasPermissions = permissions
    // Refresh partner packages
    this.currentPartnerPackages = []
    this.currentPartnerPackages = partnerSubscriptions[0]?.packages
  }

  getPermissions (): Observable<Permission> {
    return this.storage.get<Permission>(`${this.storagePrefix}permissions`).pipe(
      tap(permissions => { this.permissions = permissions ?? {} })
    )
  }

  resetPermissions (): Observable<void> {
    this.permissions = {}
    return this.storage.delete(`${this.storagePrefix}permissions`)
  }

  mergePermissions (): void {
    this.resetPermissions().subscribe()
    const packages = this.currentPartnerPackages
    let permissions

    if (packages?.length === 1 && packages[0].code === SubscriptionPackage.CARMARKET) {
      permissions = this.saasPermissions
      this.setPermissions(permissions)
    } else {
      const mergedPermissions = _.merge(
        _.keyBy(this.acl, 'permission'),
        _.keyBy(this.saasPermissions, 'code')
      )
      permissions = Object.values(mergedPermissions)
      this.setPermissions(permissions)
    }
  }

  setPermissions (permissions): void {
    permissions.forEach(prm => {
      const key = prm.code ?? prm.permission
      this.permissions[key] = {
        allowed: prm.allowed,
        read: prm.allowed || prm.read,
        create: prm.allowed || prm.create,
        update: prm.allowed || prm.update,
        delete: prm.allowed || prm.delete
      }
    })

    this.storage.set(`${this.storagePrefix}permissions`, this.permissions).subscribe()
  }
}
