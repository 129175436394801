import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import { NGXLogger } from 'ngx-logger'
import { GenericConfiguration, OmitKeys, PickKeys, ConfigurationPlaceholder, ConfigurationArrayPlaceholder } from './ef-remote-config.types'

@Injectable({
  providedIn: 'root'
})
export class EfRemoteConfigurationService<T extends GenericConfiguration> {
  constructor (
    private readonly logger: NGXLogger
  ) { }

  public static configuration<ConfigurationType> (): ConfigurationType {
    // @ts-expect-error
    return _.cloneDeep(window.ef?.remoteConfig?.configuration) as ConfigurationType
  }

  private get _configuration (): T {
    // @ts-expect-error
    return window.ef?.remoteConfig?.configuration as T
  }

  /**
   * Returns a value in the configuration specified by the given key.
   * @param key The key in the configuration.
   * @returns The value specified by the given key, or undefined if the key doesn't exist.
  */
  public get<K extends OmitKeys<T, ConfigurationPlaceholder<any> | ConfigurationArrayPlaceholder<any>>> (key: K): T[K] {
    const value = this._configuration[key]
    // if its an array, return a copy of it
    return (_.isArray(value) ? [...value] : value) as T[K]
  }

  getRequired<K extends OmitKeys<T, ConfigurationPlaceholder<any> | ConfigurationArrayPlaceholder<any>>> (key: K): T[K] {
    const value = this._configuration[key]

    if (value === null || value === undefined) {
      throw new Error(`Unable to resolve required value with key: '${String(key)}'`)
    }

    return (_.isArray(value) ? [...value] : value) as T[K]
  }

  /**
   * Return an array length of a possible array in flat JSON
   * @param prefix Path prefix in the configuration
   * @returns Array length or 0 if it's not an array
   */
  public getArrayLength (prefix: string): number {
    const regex = new RegExp(`^${prefix}.(?<i>[0-9]+)(?:\.|$)`)
    const matches = _.chain(this._configuration)
      .keys()
      .map(key => parseInt(key.match(regex)?.pop(), 10))
      .filter(index => _.isFinite(index))
      .value()

    if (matches.length > 0) {
      return _.max(matches) + 1
    }
    return 0
  }

  /**
   * Returns an array of objects with their properties, found in configuration by their path, for example, for block
   *
   * ```
   * "digitalManuals.0.name": "MERCEDES-BENZ",
   * "digitalManuals.0.link": "https://somelink",
   * "digitalManuals.1.name": "MINI",
   * "digitalManuals.1.link": "https://someotherlink"
   * ...
   *
   * remoteConfigService.getArrayValues('digitalManuals')
   * ```
   *
   * Will return array with 2 objects
   * ```
   * [
   *   {
   *     "name": "MERCEDES-BENZ",
   *     "link": "https://somelink"
   *   },
   *   {
   *     "name": "MINI",
   *     "link": "https://someotherlink"
   *   }
   * ]
   * ```
   *
   * For autocompletion to work, array must be defined in configuration interface object without array type, i.e.
   *
   * ```
   * 'digitalManuals': []
   * ```
   *
   * @param prefix Path prefix in the configuration
   * @returns Returns an array with it's objects and their properties
   */
  /**
  public getArrayValues<V extends ArrayKeys<T>>(prefix: V): T[V] {
    return this._configuration[prefix]
  }
  */
  public getArrayValues<K = any, V = PickKeys<T, ConfigurationArrayPlaceholder<K>>> (prefix: V): K {
    const regex = new RegExp(`^${String(prefix)}\\.(\\d+)\\.(.+)$`)
    const result = []

    for (const key in this._configuration) {
      const matches = key.match(regex)
      if (matches != null) {
        const index = parseInt(matches[1])
        const property = matches[2]
        const value = this._configuration[key]

        if (!result[index]) {
          result[index] = {}
        }
        result[index][property] = value
      }
    }

    return result as K
  }
}
