import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import type { com, hr } from 'efd-cdb_backend-interfaces-ts'
import type { com as insideCom } from 'inside-backend-interfaces-ts/inside_backend-ejb'
import { type Observable } from 'rxjs'
import {
  type JsonApiResponse,
  type JsonApiResponseList
} from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import {
  type AdminDealerFilter,
  type DealerDMS,
  type DealerEmployeesParams,
  DealerSettingsCard,
  type DealerSettingsModel,
  type InsideResponse
} from '../models/dealer-service.model'
import * as _ from 'lodash'
import { BaseService } from './base.service'
import type { DataSourceParameters } from '../models/efDatatable.model'
import type { FacebookPageResource } from '../models/facebook-service.model'

export type CdbDealerSalesDTO = com.mocira.inside.cdb.client.dto.CdbDealerSalesDTO
type CdbDealerDTO = com.mocira.inside.cdb.client.dto.CdbDealerDTO
type CdbDealerGroupDTO = com.mocira.inside.cdb.client.dto.CdbDealerGroupDTO
type CdbAttributeDTO = com.mocira.inside.cdb.client.dto.CdbAttributeDTO
type CdbUserDTO = com.mocira.inside.cdb.client.dto.CdbUserDTO
type CdbUserMinDTO = com.mocira.inside.cdb.client.dto.CdbUserMinDTO
type CdbDealerListDTO = com.mocira.inside.cdb.client.dto.CdbDealerListDTO
type CdbDealerServiceDTO = com.mocira.inside.cdb.client.dto.CdbDealerServiceDTO
type DealerPerBrandDTO = com.mocira.inside.cdb.rest.dto.CdbDealerPerBrandDTO
type CdbWorkingDayDTO = com.mocira.inside.cdb.client.dto.CdbWorkingDayDTO
type UserEmployeeDTO = com.mocira.inside.cdb.client.dto.UserEmployeeDTO
type CdbDealerGroupSmDTO = com.mocira.inside.cdb.client.dto.CdbDealerGroupSmDTO
type CdbDealerProfileDTO = com.mocira.inside.cdb.client.dto.CdbDealerProfileDTO
type CdbDepartmentTypeDTO = com.mocira.inside.cdb.client.dto.CdbDepartmentTypeDTO
type CdbDealerSettingsDTO = com.mocira.inside.cdb.client.dto.CdbDealerSettingsDTO
type CdbOffersAndContractsDTO = com.mocira.inside.cdb.client.dto.CdbOffersAndContractsDTO
type CdbDealerRelationDTO = com.mocira.inside.cdb.client.dto.CdbDealerRelationDTO
type DealerSettingsDTO = com.mocira.inside.cdb.client.dto.CdbDealerSettingsDTO
type CdbDepartmentDTO = com.mocira.inside.cdb.client.dto.CdbDepartmentDTO
type DealerSupplierDTO = com.mocira.inside.cdb.client.dto.CdbDealerSupplierDTO
type CdbDealerUserContactDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserContactDTO
type CdbDealerBrandCorporateIdentityDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandCorporateIdentityDTO
type PpDealerDTO = com.mocira.inside.cdb.client.dto.pp.v1.dealer.PpDealerDTO
type CdbDealerReducedDTO = com.mocira.inside.cdb.client.dto.CdbDealerReducedDTO
type CdbThirdPartySystemBasicDTO = com.mocira.inside.cdb.client.dto.CdbThirdPartySystemBasicDTO
type CdbExternalSystemParameterDTO = com.mocira.inside.cdb.client.dto.CdbExternalSystemParameterDTO
type CdbDealerBrandDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandDTO
type CdbDealerBrandInfrastructureDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandInfrastructureDTO
type CdbDealerBrandToolDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandToolDTO
type CdbCalculationSoftwareDTO = com.mocira.inside.cdb.client.dto.CdbCalculationSoftwareDTO
type LeadImporterExternalDTO = insideCom.gamewerk.inside.rest.leadimporter.dto.LeadImporterExternalDTO
type CdbUserListDTO = com.mocira.inside.cdb.client.dto.CdbUserListDTO
type CreatePartnerRequestDto = hr.emilfreydigital.cdb.partner.api.rest.v1.dto.CreatePartnerRequestDto

export interface DealerFilter {
  brand: number[]
}

interface DealerRelationParameters {
  primaryDealerId?: number
  secondaryDealerId?: number
  type?: string
}

@Injectable({
  providedIn: 'root'
})
export class DealerService extends BaseService {
  getDealer (dealerId: number): Observable<CdbDealerDTO> {
    return this.http.get<CdbDealerDTO>(`${this.apiUrl}/cdb/dealer/${dealerId}`)
  }

  getDealerAttributes (dealerId): Observable<CdbAttributeDTO[]> {
    return this.http.get<CdbAttributeDTO[]>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes`
    )
  }

  getDealerBrandAttributes (dealerBrandId): Observable<CdbAttributeDTO[]> {
    return this.http.get<CdbAttributeDTO[]>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/attributes`
    )
  }

  getDealers (filter: DealerFilter): Observable<CdbDealerDTO[]> {
    const queryFilter: { [key: string]: string | readonly string[] } = {}
    if (filter.brand) {
      queryFilter.brand = filter.brand.map(b => b.toString(10))
    }

    const params = new HttpParams({ fromObject: queryFilter })

    return this.http.get<CdbDealerDTO[]>(`${this.apiUrl}/cdb/dealer/list`, {
      params
    })
  }

  getDealerGroup (dealerId: number): Observable<CdbDealerGroupDTO[]> {
    return this.http.get<CdbDealerGroupDTO[]>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/group`
    )
  }

  /**
   *
   * @param id - dealership id
   * @param tagRole - tag role, e.g. "salesman" or array ["salesman", "salesmanager"]
   */
  getDealerEmployeesByRole (
    id: number,
    tagRole?: string | string[]
  ): Observable<CdbUserDTO[]> {
    const queryFilter: { [key: string]: string | readonly string[] } = {}
    if (tagRole) {
      queryFilter.tagName = tagRole
    }

    const params = new HttpParams({ fromObject: queryFilter })

    return this.http.get<CdbUserDTO[]>(
      `${this.apiUrl}/cdb/dealer/${id}/employee/tags`,
      {
        params
      }
    )
  }

  /**
   * Fetch dealers with appropriate dealer info + brand list.
   * @param params - see Partner Profile library AdminDealerService getDealers()
   * @returns
   */
  getDealerListWithParams (params: HttpParams): Observable<JsonApiResponseList<CdbDealerListDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerListDTO>>(
      `${this.apiUrl}/cdb/dealer`, { params: params }
    )
  }

  getDealerList (
    parameters?: DataSourceParameters,
    filter?: AdminDealerFilter,
    distinctValuesKeys?: string[],
    searchTerm?: string
  ): Observable<JsonApiResponseList<CdbDealerListDTO>> {
    const { sortColumn, sortDirection, pageIndex, pageSize } = parameters
    // todo: create type
    const params = {}

    if (pageSize) params['page[size]'] = pageSize
    if (pageIndex) params['page[number]'] = pageIndex + 1

    if (!filter?.type || filter?.type === 'internal') {
      // params['filter[externalId][IsNotNULL]'] = ''
      params['filter[type][IsNULL]'] = ''
    }
    if (filter?.type) {
      params['filter[type]'] = filter?.type
    }

    if (sortColumn && sortDirection !== 'initial') {
      params['sort'] = (sortDirection === 'desc' ? '-' : '') + sortColumn
    }

    if (distinctValuesKeys?.length) {
      for (const value of distinctValuesKeys) {
        params[`distinct[${value}]`] = ''
      }
    }

    if (searchTerm) {
      params['search'] = searchTerm
    }

    for (const filterKey in filter) {
      const filterValue = filter[filterKey]
      if (_.isArray(filterValue) && filterValue.length) {
        params[`filter[${filterKey}]`] = filterValue
      } else if (filterValue || filterValue === false) {
        params[`filter[${filterKey}]`] = filterValue
      }
    }

    const config = {
      params
    }

    return this.http.get<JsonApiResponseList<CdbDealerListDTO>>(`${this.apiUrl}/cdb/dealer`, config)
  }

  exportDealers (filter: AdminDealerFilter, searchTerm: string): Observable<Blob> {
    const params = {}

    if (!filter.type) {
      // params['filter[externalId][IsNotNULL]'] = ''
      params['filter[type][IsNULL]'] = ''
    }

    if (searchTerm) {
      params['search'] = searchTerm
    }

    for (const filterKey in filter) {
      const filterValue = filter[filterKey]
      if (_.isArray(filterValue) && filterValue.length) {
        params[`filter[${filterKey}]`] = filterValue
      } else if (filterValue || filterValue === false) {
        params[`filter[${filterKey}]`] = filterValue
      }
    }

    return this.http.get(`${this.apiUrl}/cdb/export/dealer`, { responseType: 'blob', params })
  }

  /**
   * Fetch basic dealers list with appropriate dealer info + brand list.
   * @param params - see Partner Profile library
   * @returns
   */
  getBasicDealerList (params: HttpParams): Observable<JsonApiResponseList<CdbDealerListDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerListDTO>>(
      `${this.apiUrl}/cdb/dealer/basic-list`, { params: params }
    )
  }

  public setWebTitleManager (dealerId: number, userId: number): Observable<JsonApiResponse<CdbUserMinDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData({ id: userId }))

    return this.http.put<JsonApiResponse<CdbUserMinDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/web-title-manager`, requestData
    )
  }

  getDealerServiceCategories (): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/cdb/dealer/service-categories`)
  }

  public getDealerServices (dealerId: string): Observable<CdbDealerServiceDTO[]> {
    return this.http.get<CdbDealerServiceDTO[]>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/dealer-services`
    )
  }

  public addDealerService (serviceId: number, dealerId: string): Observable<CdbDealerServiceDTO> {
    return this.http.post<CdbDealerServiceDTO>(
      `${this.apiUrl}/cdb/dealer/service/${serviceId}/dealer/${dealerId}`,
      null
    )
  }

  public deleteDealerService (dealerServiceId: number): Observable<CdbDealerServiceDTO> {
    return this.http.delete<CdbDealerServiceDTO>(
      `${this.apiUrl}/cdb/dealer/remove/dealer-service/${dealerServiceId}`
    )
  }

  public getDealerSales (dealerId: number): Observable<JsonApiResponse<CdbDealerSalesDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerSalesDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/sales`
    )
  }

  updateDealerSales (dealerId: number, dealerSalesModel: CdbDealerSalesDTO): Observable<JsonApiResponse<CdbDealerSalesDTO>> {
    const bodyData = new JsonApiRequest(
      new JsonApiRequestData(dealerSalesModel)
    )
    return this.http.put<JsonApiResponse<CdbDealerSalesDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/sales`,
      bodyData
    )
  }

  public getDealersPerBrand (brandId: number, parameters): Observable<JsonApiResponseList<DealerPerBrandDTO>> {
    let params = new HttpParams()

    params = params.append('filter[brand.id]', brandId)
    Object.keys(parameters).forEach(key => {
      params = params.append(key, parameters[key])
    })

    return this.http.get<JsonApiResponseList<DealerPerBrandDTO>>(
      `${this.apiUrl}/cdb/dealer/dealers_per_brand/list`, { params }
    )
  }

  public workdayGetAllWithMainLocation (dealerId: number, brandId?: number): Observable<CdbWorkingDayDTO[]> {
    return this.http.get<CdbWorkingDayDTO[]>(
      `${this.apiUrl}/cdb/working_day/dealer/${dealerId}${brandId !== null ? '?filter[brand.id]=' + brandId.toString() : ''}`
    )
  }

  getDealerEmployeeList (parameters: DealerEmployeesParams): Observable<JsonApiResponseList<UserEmployeeDTO>> {
    let params = new HttpParams()

    if (parameters.brandId) {
      const brandString = Array.isArray(parameters.brandId) ? parameters.brandId.join(',') : parameters.brandId
      params = params.append('filter[brands]', brandString)
    }

    if (parameters.dealerId) {
      params = params.append('filter[id]', parameters.dealerId)
    }

    if (parameters?.pageNumber) {
      params = params.append('page[number]', parameters.pageNumber)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    return this.http.get<JsonApiResponseList<UserEmployeeDTO>>(`${this.apiUrl}/cdb/user/dealer/list`, { params })
  }

  getDealerRelations (parameters: DealerRelationParameters): Observable<CdbDealerRelationDTO[]> {
    let params = new HttpParams()
    if (parameters.primaryDealerId) {
      params = params.append('primary', parameters.primaryDealerId)
    }
    if (parameters.secondaryDealerId) {
      params = params.append('secondary', parameters.secondaryDealerId)
    }
    if (parameters.type) {
      params = params.append('type', parameters.type)
    }

    return this.http.get<CdbDealerRelationDTO[]>(`${this.apiUrl}/cdb/dealer/relations`, { params })
  }

  saveDealerRelation (relation: CdbDealerRelationDTO): Observable<CdbDealerRelationDTO> {
    return this.http.post(`${this.apiUrl}/cdb/dealer/relations`, relation)
  }

  deleteDealerRelation (dealerRelationId: number): Observable<CdbDealerRelationDTO> {
    return this.http.delete(`${this.apiUrl}/cdb/dealer/relations/${dealerRelationId}`)
  }

  public relationsMasterList (fetchImporter?: boolean | null): Observable<CdbDealerDTO[]> {
    let params = new HttpParams()
    if (fetchImporter !== null && fetchImporter !== undefined) {
      params = params.append('fetchImporter', fetchImporter)
    }
    return this.http.get<CdbDealerDTO[]>(
      `${this.apiUrl}/cdb/dealer/relations/master/list`, { params: params }
    )
  }

  updateDealerSettingsCard (
    dealerId: number,
    card: DealerSettingsCard,
    dealerSettingsModel: Partial<DealerSettingsModel>
  ): Observable<JsonApiResponse<DealerSettingsDTO>> {
    const bodyData = new JsonApiRequest(
      new JsonApiRequestData(dealerSettingsModel)
    )

    return this.http.put<JsonApiResponse<DealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/settings/${card}`,
      bodyData
    )
  }

  getDepartmentsList (rolesLevel, withPermissionOnly?: boolean): Observable<JsonApiResponseList<CdbDepartmentDTO>> {
    const params: { [key: string]: string | string[] | number | boolean } = {
      'filter[level]': rolesLevel,
      'page[size]': 100
    }

    if (withPermissionOnly !== undefined) {
      params.withPermissionOnly = withPermissionOnly
    }

    const config = { params }

    return this.http.get<JsonApiResponseList<CdbDepartmentDTO>>(
      `${this.apiUrl}/cdb/department/list?sort=orderNumber,roles.orderNumber`,
      config
    )
  }

  getGroups (groupType: string = null): Observable<CdbDealerGroupSmDTO[]> {
    let params = new HttpParams()
    if (groupType !== null) params = params.append('type', groupType)

    return this.http.get<CdbDealerGroupSmDTO[]>(`${this.apiUrl}/cdb/group/list`, { params })
  }

  updateGroups(
    dealerId: number,
    groups: Array<Partial<CdbDealerGroupSmDTO>>
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}/cdb/dealer/${dealerId}/groups/`, {
      data: groups
    })
  }

  getDealerProfile (
    dealerId: number
  ): Observable<JsonApiResponseList<CdbDealerProfileDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerProfileDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/profile`
    )
  }

  getDealerDepartments (): Observable<JsonApiResponseList<CdbDepartmentTypeDTO>> {
    return this.http.get<JsonApiResponseList<CdbDepartmentTypeDTO>>(
      `${this.apiUrl}/cdb/dealer/department-types`
    )
  }

  saveDealerProfile (
    dealerId: number,
    data
  ): Observable<JsonApiResponseList<CdbDealerProfileDTO>> {
    return this.http.put<JsonApiResponseList<CdbDealerProfileDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/profile`,
      data
    )
  }

  getDealerSettings (
    dealerId: number
  ): Observable<JsonApiResponse<CdbDealerSettingsDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/settings`
    )
  }

  updateOffersAndContracts (
    dealerId: number,
    offersAndContractsDTO: CdbOffersAndContractsDTO
  ): Observable<JsonApiResponse<CdbDealerSettingsDTO>> {
    const bodyData = new JsonApiRequest(
      new JsonApiRequestData(offersAndContractsDTO)
    )

    return this.http.put<JsonApiResponse<CdbDealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/${DealerSettingsCard.OFFERS_AND_CONTRACTS}`,
      bodyData
    )
  }

  updatePaymentMethods (
    dealerId: number,
    dealerSettingsModel: Partial<DealerSettingsModel>
  ): Observable<JsonApiResponse<CdbDealerSettingsDTO>> {
    const bodyData = new JsonApiRequest(
      new JsonApiRequestData(dealerSettingsModel)
    )

    return this.http.put<JsonApiResponse<CdbDealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/payment-methods`,
      bodyData
    )
  }

  getDealerMembership (
    dealerId: number
  ): Observable<JsonApiResponse<CdbDealerSettingsDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/settings`
    )
  }

  updateDealerDms (dealerId: number, dealerDMS: DealerDMS): Observable<JsonApiResponse<CdbDealerSettingsDTO>> {
    const bodyData = new JsonApiRequest(new JsonApiRequestData(dealerDMS))
    return this.http.put<JsonApiResponse<CdbDealerSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/settings/dms-version`,
      bodyData
    )
  }

  getDealerSocialMedia (
    dealerId: number
  ): Observable<JsonApiResponse<any>> {
    return this.http.get<JsonApiResponse<any>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/social-media`
    )
  }

  putDealerSocialMedia (
    dealerId: number,
    object: any
  ): Observable<JsonApiResponse<any>> {
    const requestData = new JsonApiRequestData<any>(object)
    return this.http.put<JsonApiResponse<any>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/social-media`,
      { data: requestData }
    )
  }

  getPartnerSuppliers (dealerId: number, search?: string): Observable<JsonApiResponseList<DealerSupplierDTO>> {
    let params = new HttpParams()
    if (search) {
      params = params.append('search', search)
    }

    return this.http.get<JsonApiResponseList<DealerSupplierDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/suppliers`, { params })
  }

  removeSupplier (dealerSuplierId: number): Observable<DealerSupplierDTO> {
    return this.http.delete(`${this.apiUrl}/cdb/dealer/suppliers/${dealerSuplierId}`)
  }

  addSupplier (dealerId: number, supplier: Partial<DealerSupplierDTO>): Observable<JsonApiResponse<DealerSupplierDTO>> {
    const body = new JsonApiRequest(new JsonApiRequestData(supplier))
    return this.http.post<JsonApiResponse<DealerSupplierDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/suppliers`, body)
  }

  editSupplier (dealerSuplierId: number, supplier: Partial<DealerSupplierDTO>): Observable<JsonApiResponse<DealerSupplierDTO>> {
    const body = new JsonApiRequest(new JsonApiRequestData(supplier))
    return this.http.put<JsonApiResponse<DealerSupplierDTO>>(`${this.apiUrl}/cdb/dealer/suppliers/${dealerSuplierId}`, body)
  }

  getDealerCarmarketContacts (dealerId: number, search?: string): Observable<JsonApiResponseList<CdbDealerUserContactDTO>> {
    let params = new HttpParams()

    params = params.append('sort', '+brand')
    if (search) {
      params = params.append('search', search)
    }
    return this.http.get<JsonApiResponseList<CdbDealerUserContactDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/contacts`, { params })
  }

  createDealerCarmarketContact (data: CdbDealerUserContactDTO): Observable<JsonApiResponse<CdbDealerUserContactDTO>> {
    const body = new JsonApiRequest(new JsonApiRequestData(data))
    return this.http.post<JsonApiResponse<CdbDealerUserContactDTO>>(`${this.apiUrl}/cdb/dealer/contacts`, body)
  }

  updateDealerCarmarketContact (data: CdbDealerUserContactDTO, dealerContactId: number): Observable<JsonApiResponse<CdbDealerUserContactDTO>> {
    const body = new JsonApiRequest(new JsonApiRequestData(data))
    return this.http.put<JsonApiResponse<CdbDealerUserContactDTO>>(`${this.apiUrl}/cdb/dealer/contacts/${dealerContactId}`, body)
  }

  deleteDealerCarmarketContact (dealerContactId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/cdb/dealer/contacts/${dealerContactId}`)
  }

  /**
   * Fetches dealer info by external Id
   * @param externalId - dealer EFIT_API external ID
   */
  getDealerByExternalId(externalId: string): Observable<PpDealerDTO> {
    return this.http.get<PpDealerDTO>(
      `${this.apiUrl}/cdb/dealer/efit/${externalId}`
    )
  }

  /**
   * getDealer from pp-partner-profile/services/dealer.service.ts
  */
  getDealerById(dealerId: number, enabled?: boolean): Observable<PpDealerDTO> {
    let params = new HttpParams()
    if (enabled === false) {
      params = params.append('ignoreFilter', !enabled)
    }
    return this.http.get<PpDealerDTO>(`${this.apiUrl}/cdb/dealer/${dealerId}`, {
      params
    })
  }

  getAllNonBrandDealers(
    brandId: number
  ): Observable<JsonApiResponseList<CdbDealerDTO>> {
    const params = new HttpParams()
    params.append('filter[brand.id][NEQ]', brandId)

    return this.http.get<JsonApiResponseList<CdbDealerDTO>>(
      this.apiUrl + '/cdb/dealer/dealers_per_brand/list',
      { params }
    )
  }

  getAllDealers(brandId, parameters) {
    const params = this.setFilterParams(parameters)

    if (brandId) {
      params['filter[brand.id]'] = brandId
    }

    const config = {
      params
    }

    return this.http.get(
      this.apiUrl + '/cdb/dealer/dealers_per_brand/list',
      config
    )
  }

  setFilterParams(parameters) {
    const params = {}

    params['filter[dealer.enabled]'] = true
    params['filter[enabled]'] = true
    params['filter[externalId][IsNotNULL]'] = ''
    params['filter[dealer.type][IsNULL]'] = ''

    if (parameters?.dealerBrandCity) {
      params['filter[city]'] = parameters?.dealerBrandCity
    }

    if (parameters?.dealerBrandZip) {
      params['filter[zip]'] = parameters?.dealerBrandZip
    }

    if (parameters?.statusType) {
      const array = parameters?.statusType as string[]
      array.forEach(x => {
        params[`filter[${x}]`] = true
      })
    }

    if (parameters?.language) {
      params['filter[language][IN]'] = parameters?.language
    }

    if (parameters?.status) {
      params['filter[status]'] = parameters?.status
    }

    if (parameters?.emilFreyDealer) {
      params['filter[dealer.ef]'] = parameters?.emilFreyDealer
    }

    if (parameters?.ADealer) {
      params['filter[clientAt.id][IsNotNULL]'] = ''
    }

    if (parameters?.clientAt) {
      params['filter[clientAt.id]'] = parameters?.clientAt
      params['filter[clientAt.id][IsNotNULL]'] = ''
    }

    if (parameters?.saleZone) {
      params['filter[salesZone.name]'] = parameters?.saleZone
    }

    if (parameters?.zoneAS) {
      params['filter[aftersalesZone.name]'] = parameters?.zoneAS
    }

    if (parameters?.zoneET) {
      params['filter[sparePartsZone.name]'] = parameters?.zoneET
    }

    if (parameters?.['page[number]']) {
      params['page[number]'] = parameters['page[number]']
    }

    if (parameters?.['page[size]']) {
      params['page[size]'] = parameters['page[size]']
    }

    if (parameters?.['search']) {
      params['search'] = parameters['search']
    }

    if (parameters?.['sort']) {
      params['sort'] = parameters['sort']
    }
    return params
  }

  createNewDealer(dealerDTO: CdbDealerDTO): Observable<CdbDealerDTO> {
    return this.http.post<CdbDealerDTO>(this.apiUrl + '/cdb/dealer', dealerDTO)
  }

  createPartner (partnerData: CreatePartnerRequestDto, forceContact: boolean): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/v1/dealers?forceContact=${forceContact}`, partnerData
    )
  }

  // reduced dealer list
  /**
   * getDealers from pp-partner-profile/services/dealer.service.ts
  */
  getDealersReducedPerBrand(brandId, parameters?) {
    let params = {}
    if (brandId) params['filter[dealerBrands.brand.id]'] = brandId
    params['filter[dealerBrands.brand.id]'] = brandId
    params = { ...params, ...parameters }

    const config = {
      params
    }

    return this.http.get(
      this.apiUrl + '/cdb/dealer/list/dealers_per_brand',
      config
    )
  }

  getDealerBrands(externalDealerId: number) {
    return this.http.get(this.apiUrl + '/cdb/dealer/efit/' + externalDealerId)
  }

  saveDealer(dealer: PpDealerDTO): Observable<PpDealerDTO> {
    return this.http.put<PpDealerDTO>(
      `${this.apiUrl}/cdb/dealer/${dealer.id}`,
      dealer
    )
  }

  setDealerReduced(dealerReducedObject: CdbDealerReducedDTO) {
    const data = new JsonApiRequest(new JsonApiRequestData(dealerReducedObject))
    return this.http.put<CdbDealerReducedDTO>(
      `${this.apiUrl}/cdb/dealer/status`,
      data
    )
  }

  setEnabledStatus(enabled: boolean, dealerId: number) {
    const dealerReducedObject: CdbDealerReducedDTO = { id: dealerId, enabled }
    return this.setDealerReduced(dealerReducedObject)
  }

  // Third-party systems
  getThirdPartySystemList(
    dealerId: number
  ): Observable<CdbThirdPartySystemBasicDTO[]> {
    return this.http.get<CdbThirdPartySystemBasicDTO[]>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/third_party_system/list`
    )
  }

  setThirdPartySystemState(
    dealerId: number,
    enabled: boolean,
    platform: string
  ): Observable<any> {
    let queryParams = null
    if (platform && platform !== '') {
      queryParams = `&platform=${platform}`
    }
    return this.http.put(
      `${this.apiUrl}/cdb/dealer/${dealerId}/third_party_system/state${queryParams}`,
      enabled
    )
  }

  // External system config
  getExternalSystemParameterList(
    externalSystemConfigId: number
  ): Observable<CdbExternalSystemParameterDTO[]> {
    return this.http.get<CdbExternalSystemParameterDTO[]>(
      `${this.apiUrl}/cdb/dealer/external/system/parameter/${externalSystemConfigId}/list`
    )
  }

  saveExternalSystemParameter(
    externalSystemConfigId: number,
    paramsList: CdbExternalSystemParameterDTO[]
  ): Observable<CdbExternalSystemParameterDTO[]> {
    return this.http.post<CdbExternalSystemParameterDTO[]>(
      `${this.apiUrl}/cdb/dealer/external/system/parameter/${externalSystemConfigId}/bulk`,
      paramsList
    )
  }

  // brand specific - DMS/INSiDE
  getDMSList() {
    return this.http.get(`${this.apiUrl}/cdb/dealer/dms/list`)
  }

  getCalculationSoftwareList(): Observable<
    JsonApiResponseList<CdbCalculationSoftwareDTO>
  > {
    return this.http.get<JsonApiResponseList<CdbCalculationSoftwareDTO>>(
      this.apiUrl + '/cdb/dealer/calculation-sw/list'
    )
  }

  getDealerBrand(dealerId: number) {
    return this.http.get<CdbDealerBrandDTO>(
      this.apiUrl + '/cdb/dealer/brand/' + dealerId
    )
  }

  saveDealerBrand(id: number, data) {
    return this.http.put(this.apiUrl + '/cdb/dealer/brand/' + id, data)
  }

  // DealerBrandInfrastructure
  getDealerBrandInfrastructure(
    dealerBrandId: number
  ): Observable<JsonApiResponse<CdbDealerBrandInfrastructureDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerBrandInfrastructureDTO>>(
      `${this.apiUrl}/cdb/infrastructure/dealer/brand/${dealerBrandId}`
    )
  }

  saveDealerBrandInfrastructure(
    infrastructureData
  ): Observable<JsonApiResponse<CdbDealerBrandInfrastructureDTO>> {
    return this.http.post<JsonApiResponse<CdbDealerBrandInfrastructureDTO>>(
      this.apiUrl + '/cdb/infrastructure',
      { data: { attributes: infrastructureData } }
    )
  }

  updateDealerBrandInfrastructure(
    infrastructureData
  ): Observable<JsonApiResponse<CdbDealerBrandInfrastructureDTO>> {
    return this.http.put<JsonApiResponse<CdbDealerBrandInfrastructureDTO>>(
      this.apiUrl + '/cdb/infrastructure',
      { data: { id: infrastructureData.id, attributes: infrastructureData } }
    )
  }

  getTools(
    dealerBrandId: number
  ): Observable<JsonApiResponseList<CdbDealerBrandToolDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerBrandToolDTO>>(
      `${this.apiUrl}/cdb/infrastructure/dealer/brand/${dealerBrandId}/tool`
    )
  }

  saveTools(dealerId: number, data) {
    return this.http.post(
      `${this.apiUrl}/cdb/infrastructure/dealer/brand/${dealerId}/tool`,
      data
    )
  }

  // get parent dealer
  getParentDealers(brandId: number) {
    return this.http.get(`${this.apiUrl}/cdb/dealer/list/clean?brand=${brandId}`)
  }

  // get zones
  getZones(brandId: number) {
    const params = {}
    if (brandId) params['filter[brand.id]'] = brandId
    params['sort'] = 'name'

    const config = {
      params
    }

    return this.http.get(`${this.apiUrl}/cdb/brand_zone/list`, config)
  }

  // get dealer children and get children count
  getDealerChildren(dealerId: number) {
    return this.http.get(
      `${this.apiUrl}/cdb/dealer/relations?type=filiale&primary=${dealerId}`
    )
  }

  getDealerStatus(dealerId: number) {
    return this.http.get(`${this.apiUrl}/cdb/dealer/brand/${dealerId}/status`)
  }

  // corporate identity
  getCi(id: number) {
    return this.http.get(
      `${this.apiUrl}/cdb/dealer/brand/corporate_identity/${id}`
    )
  }

  getdealerBrandCi(
    dealerBrandId: number
  ): Observable<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/corporate-identities`
    )
  }

  saveDealerBrandCi(
    data: CdbDealerBrandCorporateIdentityDTO
  ): Observable<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>> {
    const wrappedData = JsonApiRequest.of(data)

    return this.http.post<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/corporate-identities`,
      wrappedData,
      { headers: this.headers }
    )
  }

  updateDealerBrandCi(
    data: CdbDealerBrandCorporateIdentityDTO
  ): Observable<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>> {
    const wrappedData = JsonApiRequest.of(data, data.id)

    return this.http.put<JsonApiResponse<CdbDealerBrandCorporateIdentityDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/corporate-identities`,
      wrappedData,
      { headers: this.headers }
    )
  }

  saveDealerExternalId(dealerId: number, data) {
    return this.http.post(
      `${this.apiUrl}/cdb/dealer/${dealerId}/externalId/`,
      data
    )
  }

  /**
   * exportDealers from pp-partner-profile/services/dealer.service.ts
  */
  exportDealersImporter(parameters, brandId: number) {
    // delete page and table parameters
    if (parameters['page[size]']) delete parameters['page[size]']
    if (parameters['page[number]']) delete parameters['page[number]']
    if (parameters['page[sort]']) delete parameters['page[sort]']
    if (parameters['emilFreyDealer']?.length === 1) {
      const isEfDealer = parameters['emilFreyDealer'][0]
      parameters['filter[dealer.ef]'] = isEfDealer
    }
    // add brand id to parameters
    parameters['filter[brand.id]'] = brandId
    return this.http.get(`${this.apiUrl}/cdb/export/importer`, {
      responseType: 'blob',
      params: parameters
    })
  }

  exportEmployees(parameters, brandId: number) {
    // delete page and table parameters
    if (parameters['page[size]']) delete parameters['page[size]']
    if (parameters['page[number]']) delete parameters['page[number]']
    if (parameters['page[sort]']) delete parameters['page[sort]']
    if (parameters['emilFreyDealer']?.length === 1) {
      const isEfDealer = parameters['emilFreyDealer'][0]
      parameters['filter[dealer.ef]'] = isEfDealer
    }

    // add brand id to parameters
    parameters['filter[brand.id]'] = brandId

    return this.http.get(`${this.apiUrl}/cdb/export/importer/employees`, {
      responseType: 'blob',
      params: parameters
    })
  }

  exportAllDealerUsers(parameters, brandId: number) {
    // delete page and table parameters
    if (parameters['page[size]']) delete parameters['page[size]']
    if (parameters['page[number]']) delete parameters['page[number]']
    if (parameters['page[sort]']) delete parameters['page[sort]']
    // add brand id to parameters
    parameters['filter[brand.id]'] = brandId

    return this.http.get(`${this.apiUrl}/cdb/export/employees`, {
      responseType: 'blob',
      params: parameters
    })
  }

  getDealerPages(
    dealerId: number
  ): Observable<InsideResponse<LeadImporterExternalDTO>> {
    return this.http.get<InsideResponse<LeadImporterExternalDTO>>(
      this.dataUrl + 'lead-importer-external?dealer_id=' + dealerId
    )
  }

  removePageFromDealer(
    externalId: number
  ): Observable<InsideResponse<LeadImporterExternalDTO>> {
    return this.http.delete<InsideResponse<LeadImporterExternalDTO>>(
      this.dataUrl + 'lead-importer-external/' + externalId
    )
  }

  getAllPages(
    platformIds: string[]
  ): Observable<InsideResponse<LeadImporterExternalDTO>> {
    return this.http.get<InsideResponse<LeadImporterExternalDTO>>(
      this.dataUrl +
        'lead-importer-external?platform_ids=' +
        platformIds.join(',')
    )
  }

  saveDealerPage(
    externalUserId: string,
    userAccessToken: string,
    dealerId: number,
    page: FacebookPageResource
  ): Observable<InsideResponse<LeadImporterExternalDTO>> {
    const details = page

    return this.http.post<InsideResponse<LeadImporterExternalDTO>>(
      this.dataUrl +
        'lead-importer-external?external_user_id=' +
        externalUserId,
      {
        platform: 'FACEBOOK',
        platformId: page.id,
        dealerId,
        accessToken: userAccessToken,
        details: JSON.stringify(details)
      }
    )
  }

  getDealerUsers(currentDealerId: number): Observable<CdbUserListDTO[]> {
    return this.http.get<CdbUserListDTO[]>(
      `${this.apiUrl}/cdb/dealer/${currentDealerId}/users/list`
    )
  }

  getEmployeeData (pageNumber?, pageSize?, search?, userDealersOnly?) {
    if (!pageNumber) {
      pageNumber = 0
    }

    if (!pageSize) {
      pageSize = 50
    }

    const queryParams = {
      pageNumber: pageNumber,
      pageSize: pageSize
    }

    if (search) {
      queryParams['search'] = search
    }

    if (userDealersOnly) {
      queryParams['userDealersOnly'] = true
    }

    queryParams['reduced'] = true

    return this.http.get(`${this.apiUrl}/cdb/dealer/employee/all`, {
      params: queryParams
    })
  }
}
